import "../../pages/payment/index.css";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { postApi } from "../../ApiServices/api";
import { apiEndPoints } from "../../ApiServices/config";
import toast from "react-hot-toast";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormHelperText,
  styled,
} from "@mui/material";
import { ArrowForwardIosSharp } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { getApi } from "../../ApiServices/api";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { updateCart } from "../../actions";
import { yupResolver } from "@hookform/resolvers/yup";
import { MuiTelInput } from "mui-tel-input";

// Define yup schema for form validation
const schema = yup.object().shape({
  email: yup.string().email().required("Please Enter your Email"),
  country: yup.string().required("Country is required"),
  state: yup.string().required("State is required"),
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  address: yup.string().required("Address is required"),
  city: yup.string().required("City is required"),
  zipCode: yup.string().required("Zip Code is required"),
});

const CustomAccordion = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const CustomAccordionSummary = styled((props) => (
  <AccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function Checkout() {
  const location = useLocation();
  const currentUrlPath = location.pathname;
  const [message, setMessage] = useState("");
  const [searchParams] = useSearchParams();
  const isDevoteeLoggedIn = localStorage.getItem("token");
  const devoteeData = JSON.parse(localStorage.getItem("devoteeData"));
  const serviceCartArr = useSelector((state) => state.cartArr);
  const [totalAmount, setTotalAmount] = useState(0);
  const [expanded, setExpanded] = React.useState({});
  const [country, setCountry] = useState();
  const [state, setState] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const total = serviceCartArr.reduce(
      (total, item) => total + Number(item.amount) * (item.quantity || 1),
      0
    );
    setTotalAmount(total);
  }, [serviceCartArr]);

  const productData = serviceCartArr?.map((item) => ({
    total: totalAmount,
    items: {
      _id: item._id,
      type: item.type,
      type_id: item.type_id,
      name: item.title,
      quantity: item.quantity || 1,
      price: item.amount,
      date: item.selectedDate || new Date(),
      cut_off_hours: item.cut_off_hours,
      cut_off_service_time: item.cut_off_service_time,
      cut_off_type: item.cut_off_type,
      location: item.location,
      booking_config: item.booking_config,
      row_data: {},
      page: item.page,
    },
  }));
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      await schema.validate(data);
      if (isDevoteeLoggedIn) {
        data.user_id = await devoteeData?.user_id;
        data.sankalp = null;
        const response = await postApi(apiEndPoints.stripePayment, {
          productData: productData,
          customerData: data,
          bookingId: "",
        });
        if (response?.data?.status === 1) {
          setIsLoading(false);
          localStorage.setItem("orderId", response.data.orderData._id);
          setTimeout(() => {
            window.location.href = response?.data?.url;
          }, 1000);
        } else {
          toast.error("Something went wrong");
          setIsLoading(false);
        }
      } else {
        if (devoteeData?.persons) {
          data.persons = devoteeData?.persons;
          localStorage.setItem("devoteeData", JSON.stringify(data));
        } else {
          localStorage.setItem("devoteeData", JSON.stringify(data));
        }
        navigate("/checkout-details");
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong");
      console.log(error);
    }
  };

  // service product display related data-----------------------------

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded((prev) => ({
      ...prev,
      [panel]: newExpanded ? panel : false,
    }));
  };

  const getCountries = async () => {
    const response = await getApi(apiEndPoints.countries);
    if (response && response.status === 200) {
      setCountry(response?.data?.data);
    }
  };

  const getState = async (countryId) => {
    const response = await postApi(apiEndPoints.state, {
      country_id: countryId ? countryId : "611ce96de2cd656171314e2a",
    });
    if (response && response.status === 200) {
      setState(response?.data?.data);
    }
  };

  useEffect(() => {
    getCountries();
    getState(undefined);
  }, []);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    setError,
    reset,
    trigger,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    getState(watch("country"));
  }, [watch("country")]);

  useEffect(() => {
    if (isDevoteeLoggedIn) {
      reset({
        email: devoteeData?.email,
        first_name: devoteeData?.first_name,
        last_name: devoteeData?.last_name,
        country: devoteeData?.country,
        address: devoteeData?.address,
        city: devoteeData?.city,
        contact_number: String(devoteeData?.contact_number || ""),
        zipCode: devoteeData?.zipCode || "",
      });
    } else if (!isDevoteeLoggedIn & true) {
      reset({
        email: devoteeData?.email,
        first_name: devoteeData?.first_name,
        last_name: devoteeData?.last_name,
        country: devoteeData?.country,
        state: devoteeData?.state,
        address: devoteeData?.address,
        city: devoteeData?.city,
        contact_number: String(devoteeData?.contact_number || ""),
        zipCode: devoteeData?.zipCode || "",
      });
    }
  }, [isDevoteeLoggedIn]);

  const handleEmptyPoojaBasket = () => {
    dispatch(updateCart());
  };

  return (
    <section className="w-100 justify-content-center align-items-center">
      <div className="mb-5">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="d-flex justify-content-center align-items-center"
          style={{ flexDirection: "column" }}
        >
          {/* //service product display-------------------------------------------------------------------  */}
          {/* <ServicesProductDisplay onSubmit={onSubmit} /> */}
          <>
            <div className="container-fluid page-header py-4 breadcrumb-bg">
              <h1 className="text-center display-6 breadcrumb-h1">
                Payment Details
              </h1>
              <ol className="breadcrumb justify-content-center mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Proceed to Pay</li>
              </ol>
            </div>
            <div className="container py-5">
              <div className="text-center mb-4">
                <h6 className="section-title text-center text-primary text-uppercase title-tl">
                  Payment
                </h6>
                <h1 className="mb-5">
                  <span className="text-secundary">Details</span>
                </h1>
              </div>

              <div className="row">
                <div className="col-lg-4 col-md-4">
                  {!isDevoteeLoggedIn && (
                    <p>
                      Returning Devotee?{" "}
                      <Link to={`/login?redirect=${currentUrlPath}`}>
                        Click here to login.
                      </Link>
                    </p>
                  )}

                  <div className="d-flex justify-content-between">
                    <h5 className="sub-title-h5 mb-2">
                      {isDevoteeLoggedIn ? "Devotee Details" : "Guest Details"}
                    </h5>
                  </div>
                  <form>
                    <FormControl fullWidth>
                      <Controller
                        control={control}
                        name="country"
                        render={({ field, fieldState }) => {
                          return (
                            <FormControl
                              key={field.value}
                              fullWidth
                              error={errors && errors.country ? true : false}
                              className="dropdown-margin-top"
                            >
                              <InputLabel id="demo-simple-select-label">
                                Select Country *
                              </InputLabel>
                              <Select
                                {...field}
                                labelId="demo-simple-select-label"
                                label="Select Country *"
                                defaultValue={field.value}
                              >
                                {country?.map((item) => (
                                  <MenuItem key={item._id} value={item._id}>
                                    {item.name}
                                  </MenuItem>
                                ))}
                              </Select>
                              {errors && errors.country && (
                                <FormHelperText error>
                                  {errors &&
                                  errors.country &&
                                  errors.country.message
                                    ? errors.country.message
                                    : ""}
                                </FormHelperText>
                              )}
                            </FormControl>
                          );
                        }}
                      />
                    </FormControl>

                    <FormControl fullWidth>
                      <Controller
                        control={control}
                        name="state"
                        render={({ field, fieldState }) => {
                          return (
                            <FormControl
                              key={field.value}
                              fullWidth
                              error={errors && errors.country ? true : false}
                              className="dropdown-margin-top"
                            >
                              <InputLabel id="demo-simple-select-label">
                                Select State
                              </InputLabel>
                              <Select
                                {...field}
                                labelId="demo-simple-select-label"
                                label="Select State *"
                                defaultValue={field.value}
                              >
                                {state?.map((item) => (
                                  <MenuItem key={item._id} value={item._id}>
                                    {item.name}
                                  </MenuItem>
                                ))}
                              </Select>
                              {errors && errors.country && (
                                <FormHelperText error>
                                  {errors &&
                                  errors.state &&
                                  errors.state.message
                                    ? errors.state.message
                                    : ""}
                                </FormHelperText>
                              )}
                            </FormControl>
                          );
                        }}
                      />
                    </FormControl>
                    <TextField
                      {...register("email")}
                      margin="normal"
                      fullWidth
                      label="Email *"
                      error={errors && errors.email && errors?.email?.message}
                      helperText={
                        errors && errors.email && errors?.email?.message
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <Controller
                      control={control}
                      name="contact_number"
                      render={({ field }) => {
                        return (
                          <MuiTelInput
                            {...field}
                            defaultCountry="US"
                            sx={{ mt: "16px", mb: "8px" }}
                            fullWidth
                            label="Conatct Number"
                            error={errors?.home_phone?.message}
                            helperText={errors?.home_phone?.message}
                          />
                        );
                      }}
                    />
                    <TextField
                      className="mt-2"
                      fullWidth
                      {...register("first_name")}
                      id="outlined-basic"
                      label="First Name *"
                      variant="outlined"
                      error={
                        errors && errors.first_name && errors.first_name.message
                      }
                      helperText={
                        errors && errors.first_name && errors.first_name.message
                          ? errors.first_name.message
                          : ""
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      className="mt-2"
                      {...register("last_name")}
                      fullWidth
                      id="outlined-basic"
                      label="Last Name *"
                      variant="outlined"
                      error={
                        errors && errors.last_name && errors.last_name.message
                          ? errors.last_name.message
                          : ""
                      }
                      helperText={
                        errors && errors.last_name && errors.last_name.message
                          ? errors.last_name.message
                          : ""
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      {...register("company_name")}
                      fullWidth
                      id="outlined-basic"
                      label="Company Name"
                      variant="outlined"
                    />
                    <TextField
                      {...register("address")}
                      fullWidth
                      id="outlined-basic"
                      label="Address *"
                      variant="outlined"
                      error={
                        errors && errors.address && errors.address.message
                          ? errors.address.message
                          : ""
                      }
                      helperText={
                        errors && errors.address && errors.address.message
                          ? errors.address.message
                          : ""
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      {...register("zipCode")}
                      fullWidth
                      id="outlined-basic"
                      label="Zip Code *"
                      variant="outlined"
                      error={
                        errors && errors.zipCode && errors.zipCode.message
                          ? errors.zipCode.message
                          : ""
                      }
                      helperText={
                        errors && errors.zipCode && errors.zipCode.message
                          ? errors.zipCode.message
                          : ""
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      {...register("city")}
                      fullWidth
                      id="outlined-basic"
                      label="City *"
                      variant="outlined"
                      error={
                        errors && errors.city && errors.city.message
                          ? errors.city.message
                          : ""
                      }
                      helperText={
                        errors && errors.city && errors.city.message
                          ? errors.city.message
                          : ""
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <FormControlLabel
                      {...register("use_for_billing")}
                      control={<Checkbox defaultChecked />}
                      label={`Use ${
                        isDevoteeLoggedIn ? "Devoteee Details" : "Guest Details"
                      }  for billing`}
                    />
                  </form>
                </div>
                <div className="col-lg-8 col-md-8">
                  <div className="container religious-service-product">
                    <div className="d-flex justify-content-between">
                      <h5 className="font-weight-semi-bold mb-2">
                        Payment Details
                      </h5>
                      <div
                        className="empty-btn"
                        onClick={handleEmptyPoojaBasket}
                      >
                        Empty Puja Basket
                      </div>
                    </div>
                    <div className="custom-control custom-checkbox mb-2">
                      <>
                        {serviceCartArr?.length > 0 &&
                          serviceCartArr?.map((item) => (
                            <>
                              <CustomAccordion
                                expanded={
                                  expanded[item._id] == undefined
                                    ? true
                                    : expanded[item._id]
                                }
                                onChange={handleChange(item._id)}
                              >
                                <CustomAccordionSummary
                                  aria-controls="panel1d-content"
                                  id="panel1d-header"
                                >
                                  <Typography className="product-title">
                                    {item?.title}
                                  </Typography>
                                </CustomAccordionSummary>
                                <CustomAccordionDetails>
                                  <Typography>
                                    <ul className="payment-detail">
                                      <li>
                                        {item?.title}
                                        <span>$ {item?.amount}</span>
                                      </li>
                                      <li>
                                        Date
                                        {item?.selectedDate ? (
                                          <span>
                                            {moment
                                              .utc(new Date(item?.selectedDate))
                                              .format("dddd, MMMM Do YYYY")}
                                          </span>
                                        ) : (
                                          <span>
                                            {moment
                                              .utc(new Date())
                                              .format("dddd, MMMM Do YYYY")}
                                          </span>
                                        )}
                                      </li>
                                      {item.category && (
                                        <li>
                                          Category{" "}
                                          <span>{`${item.category}`}</span>
                                        </li>
                                      )}

                                      <li>
                                        Quantity{" "}
                                        <span>{item?.quantity || 1}</span>
                                      </li>
                                      <li>
                                        Amount{" "}
                                        <span>
                                          ${item.amount * (item.quantity || 1)}
                                        </span>
                                      </li>

                                      <li className="service-details-book">
                                        {item?.booking_config}
                                      </li>
                                    </ul>
                                  </Typography>
                                </CustomAccordionDetails>
                              </CustomAccordion>
                            </>
                          ))}
                      </>

                      <ul className="detail-list">
                        <li className="service-details-total">
                          Total Amount: <span>$ {totalAmount}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-7 col-md-7">
                      <h5 className="sub-title-h5 mb-2">Puja Basket Total</h5>
                      <ul className="detail-list">
                        {serviceCartArr?.length > 0 &&
                          serviceCartArr?.map((item) => (
                            <>
                              <li className="service-details-total">
                                {item.title}{" "}
                                <span>$ {item.amount * item.quantity}</span>
                              </li>
                            </>
                          ))}

                        <li className="service-details-total">
                          Order Total <span>$ {totalAmount}</span>
                        </li>
                      </ul>
                      {/* //service product display--------------*/}
                      <button
                        className="btn d-flex align-items-center"
                        type="submit"
                        disabled={isLoading}
                      >
                        Pay Now
                        {isLoading && (
                          <CircularProgress
                            color="inherit"
                            className="mx-2 text-center"
                            size={24}
                          />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </form>
      </div>
    </section>
  );
}
