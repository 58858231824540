import React from "react";
import "./religiousservices.css";
import PoojaBasket from "../../../components/common/Basket/PoojaBasket";
import { Link } from "react-router-dom";
import {
  useReligiousServicesFunctions,
  useServicesCommonFunctions,
} from "../customHooks";
import { Box, Card, Grid, Stack } from "@mui/material";
import ServiceCard from "../../../components/common/cards/ServiceCard";
import { logEvent } from "../../../utils/analytics";

function ReligiousServices() {
  const { religiousServices } = useReligiousServicesFunctions();
  const {
    selectedDate,
    quantityCounts,
    setSelectedDate,
    handleOrderPuja,
    increaseQuantity,
    decreaseQuantity,
  } = useServicesCommonFunctions();

  const handleOrderClick = (i) => {
    handleOrderPuja(i, "Daily Service", "services/daily");
    logEvent("Daily Puja Request", "Request Puja Clicked", i.title);
  };

  const shouldDisableDate = (date, i) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    new Date(date).setHours(0, 0, 0, 0);
    return date < currentDate;
  };
  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">
          Religious Services
        </h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/servicesattemple">Services</Link>
          </li>
          <li className="breadcrumb-item active">Religious Services</li>
        </ol>
      </div>
      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Durga Temple
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Religious Services</span>
          </h1>
        </div>
        <p>
          <strong>Please Note:</strong> The schedule may be changed due to
          special events held in temple on that day.
        </p>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={9} md={9} lg={9}>
            <Grid container spacing={2}>
              {religiousServices?.map((i) => (
                <Grid
                  key={i._id}
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={4}
                  className="equal-height"
                >
                  <ServiceCard
                    i={i ? i : []}
                    increaseQuantity={increaseQuantity}
                    decreaseQuantity={decreaseQuantity}
                    handleOrderClick={handleOrderClick}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    quantityCounts={quantityCounts}
                    type={"atTemple"}
                    shouldDisableDate={shouldDisableDate}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>

          {/* basket ------------------------------------------- */}
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <PoojaBasket />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default ReligiousServices;
