import * as React from "react";
import "./CalendarView.css";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { apiEndPoints } from "../../../../ApiServices/config";
import { postApi } from "../../../../ApiServices/api";
import { useNavigate } from "react-router-dom";
import "moment-timezone";
import { logEvent } from "../../../../utils/analytics";

const localizer = momentLocalizer(moment);
const formatEventTime = (start, end, timeZone = "America/New_York") => {
  const startTime = moment.tz(start, timeZone).format("hh:mm A");
  const endTime = moment.tz(end, timeZone).format("hh:mm A");

  return `${startTime} - ${endTime}`;
};

const CustomEvent = ({ event, currentView, onRightClick }) => {
  const handleRightClick = (e) => {
    e.preventDefault();
    onRightClick(event);
  };
  const eventTime = formatEventTime(event.start, event.end);

  return currentView === "month" ? (
    <div
      className="custom-event"
      style={{ padding: "2px", color: "#fff" }}
      onContextMenu={handleRightClick}
    >
      <div style={{ fontWeight: "bold" }}>{eventTime}</div>
      <div className="event-title">{event?.title}</div>
    </div>
  ) : (
    <span className="event-title" onContextMenu={handleRightClick}>
      {event.title}
    </span>
  );
};

const CustomToolbar = (toolbar) => {
  const { view, date } = toolbar;

  const goToBack = () => {
    const newDate = moment(date)
      .subtract(
        1,
        view === "month" ? "months" : view === "week" ? "weeks" : "days"
      )
      .toDate();
    toolbar.onNavigate("prev", newDate);
  };

  const goToNext = () => {
    const newDate = moment(date)
      .add(1, view === "month" ? "months" : view === "week" ? "weeks" : "days")
      .toDate();
    toolbar.onNavigate("next", newDate);
  };

  const goToCurrent = () => {
    const now = moment();

    if (view === "month") {
      const currentMonth = now.startOf("month").toDate();
      toolbar.onNavigate("date", currentMonth);
    } else if (view === "week") {
      const startOfWeek = now.startOf("week").toDate();
      toolbar.onNavigate("date", startOfWeek);
    } else if (view === "day") {
      toolbar.onNavigate("date", now.toDate());
    }
  };
  const getLabel = () => {
    const date = moment(toolbar.date);
    if (view === "month") return `This Month: ${date.format("MMMM YYYY")}`;
    if (view === "week") return `This Week: ${date.format("MMM D YYYY")}`;
    if (view === "day") return `Today: ${date.format("ddd, MMM D YYYY")}`;
    return date.format("MMMM YYYY");
  };

  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <button onClick={goToBack}>Back</button>
        <button onClick={goToCurrent}>
          {view === "month" ? "Month" : view === "week" ? "Week" : "Day"}
        </button>
        <button onClick={goToNext}>Next</button>
      </span>
      <span className="rbc-toolbar-label">{getLabel()}</span>
      <span className="rbc-btn-group">
        <button onClick={() => toolbar.onView("month")}>Month</button>
        <button onClick={() => toolbar.onView("week")}>Week</button>
        <button onClick={() => toolbar.onView("day")}>Day</button>
        <button onClick={() => toolbar.onView("agenda")}>List</button>
      </span>
    </div>
  );
};

function CalendarView() {
  const [events, setEvents] = useState([]);
  const [currentView, setCurrentView] = useState("month");
  const [contextEvent, setContextEvent] = useState(null);

  const localizer = momentLocalizer(moment);
  const location = useLocation();
  const navigate = useNavigate();

  const handleEventClick = (event) => {
    navigate(`/knowmoreevents/${event.slug}`);
    logEvent("Upcoming Events", "Know more event Clicked", event.title);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const viewParam = queryParams.get("view");
    console.log("viewParam:-", viewParam);

    if (viewParam === "monthly") {
      setCurrentView("month");
    }
    if (viewParam === "weekly") {
      setCurrentView("week");
    }
    if (viewParam === "today") {
      setCurrentView("day");
    }
  }, [location]);

  const viewSchedule = async () => {
    const timeZone = "America/New_York";

    const response = await postApi(apiEndPoints.getUpcomingEvents);
    const mergedArray = [
      ...(response.data?.today || []),
      ...(response.data?.weekly || []),
      ...(response.data?.monthly || []),
    ].map((event) => ({
      ...event,
      start: moment.tz(event.start, "MM/DD/YYYY hh:mm A", timeZone).toDate(),
      end: moment.tz(event.end, "MM/DD/YYYY hh:mm A", timeZone).toDate(),
      allDay: false,
    }));

    setEvents(removeDuplicates(mergedArray));
  };

  const removeDuplicates = (events) => {
    const uniqueEvents = {};
    events.forEach((event) => {
      const key = `${event.start}-${event.end}-${event.title}`;
      if (!uniqueEvents[key]) uniqueEvents[key] = event;
    });
    return Object.values(uniqueEvents);
  };

  useEffect(() => {
    viewSchedule();
  }, []);

  const handleRightClick = (event) => {
    setContextEvent(event);
    generateICSFile(event);
  };

  const generateICSFile = (event) => {
    const { title, start, end } = event;
    const icsContent = `
BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
SUMMARY:${title}
DTSTART:${moment(start).format("YYYYMMDDTHHmmss")}
DTEND:${moment(end).format("YYYYMMDDTHHmmss")}
DESCRIPTION:${title}
END:VEVENT
END:VCALENDAR
    `.trim();

    const blob = new Blob([icsContent], {
      type: "text/calendar;charset=utf-8",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${title.replace(/\s+/g, "_")}.ics`;
    link.click();
  };

  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">Calendar View</h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/">Events & Activities</Link>
          </li>
          <li className="breadcrumb-item active">Calendar View</li>
        </ol>
      </div>
      <div className="container py-5">
        <div style={{ height: 500, width: "100%" }}>
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            defaultDate={new Date()}
            view={currentView}
            onView={(view) => setCurrentView(view)}
            onSelectEvent={handleEventClick}
            components={{
              event: (props) => (
                <CustomEvent
                  {...props}
                  currentView={currentView}
                  onRightClick={handleRightClick}
                />
              ),
              toolbar: CustomToolbar,
            }}
          />
        </div>
      </div>
    </>
  );
}

export default CalendarView;
