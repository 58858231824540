import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./newsandevents.css";
import { getApi, postApi } from "../../ApiServices/api";
import { apiEndPoints } from "../../ApiServices/config";
import Pagination from "@mui/material/Pagination";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

function HomePageNews() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [count, setCount] = useState();
  const [page, setPage] = useState(searchParams.get("page") || 1);
  const [news, setNews] = useState();
  const navigate = useNavigate();
  const [dailyEvents, setDailyEvents] = useState();

  const handleChange = (event, value) => {
    setPage(value);
    setSearchParams({ page: value });
    window.scrollTo(0, 0);
  };

  const newsPayload = {
    page: page,
  };

  const getNews = async () => {
    const response = await postApi(
      apiEndPoints.getAllPaginatedEvents,
      newsPayload
    );
    if (response && response.status === 200) {
      setNews(response?.data?.data);
      setCount(response.data.total_pages);
    }
  };

  const getLatestEvents = async () => {
    const response = await getApi(apiEndPoints.allHomePageEvents);
    if (response && response.status === 200) {
      setDailyEvents(response?.data?.data);
    }
  };

  useEffect(() => {
    getNews();
    getLatestEvents();
  }, [page]);

  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">Events</h1>
        <ul className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Events </li>
        </ul>
      </div>

      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Events
          </h6>
        </div>
        <div className="container">
          <div className="row">
            {/* all news section  */}
            <div className="col-lg-8 col-md-8">
              <div className="row">
                {news &&
                  news?.map((item) => (
                    <div className="col-lg-9 col-md-9">
                      <div
                        className="row"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate(`/knowmoreevents/${item.slug}`);
                        }}
                      >
                        <div className="col-lg-4 col-md-6">
                          <img
                            src={item.image}
                            alt="Events"
                            className="img-max"
                          />
                        </div>
                        <div className="col-lg-8 col-md-6">
                          <div className="events-content">
                            <div className="d-flex justify-content-between mb-4">
                              <div className="p-2 timep">
                                Date : <span>{item.full_start}</span>
                              </div>
                            </div>
                            <h2 className="ttl2-h2"> {item.title}</h2>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}
                            ></p>
                            {/* <p>
                              Please click here to
                              <a href="https://durgatemple.org/UploadFiles/sundermarch3024.pdf">
                                donate
                              </a>
                              or make check payable to Durga Temple
                            </p> */}
                          </div>
                        </div>
                      </div>
                      <div className="devider">
                        <img
                          src="./images/devider.png"
                          alt="devider"
                          className="img-max"
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            {/* Latest news section-------------------------------------------------------------- */}
            <div className="col-lg-4 col-md-4">
              <h2 className="ttl2-h2">Latest Events</h2>
              <ul className="latest-news">
                {dailyEvents &&
                  dailyEvents.map((item) => (
                    <li>
                      <Link to={`/knowmoreevents/${item.slug}`}>
                        {moment(item?.full_start)
                          .tz("America/New_York")
                          .format("MM / DD / YY")}{" "}
                        : {item.title}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>

        <hr />
        <div className="d-flex justify-content-center align-items-center m-3">
          <Pagination
            count={count}
            variant="outlined"
            shape="rounded"
            page={page}
            size="large"
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  );
}

export default HomePageNews;
