import { Link } from "react-router-dom";
import React from "react";
import { logEvent } from "../../../../utils/analytics";

const formatEventDate = (start, end) => {
  const startDate = new Date(start);
  const endDate = new Date(end);

  const options = {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
  };

  if (startDate.toDateString() === endDate.toDateString()) {
    return `${startDate.toLocaleDateString(
      "en-US",
      options
    )} ${startDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    })} - ${endDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    })}`;
  }

  const startOptions = {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
  };
  const endTimeOptions = { hour: "2-digit", minute: "2-digit" };

  return `${startDate.toLocaleDateString(
    "en-US",
    startOptions
  )} - ${endDate.toLocaleDateString(
    "en-US",
    startOptions
  )} ${startDate.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  })} to ${endDate.toLocaleTimeString([], endTimeOptions)} Daily`;
};

const Weekly = (events) => {
  return (
    <div>
      {events?.events?.length > 0 ? (
        <ul className="monthlyevents-list">
          {events?.events?.map((event, index) => {
            return (
              <li key={index}>
                <div>
                  <span>{formatEventDate(event?.start, event?.end)}</span>
                  <Link
                    to={`/knowmoreevents/${event.slug}`}
                    onClick={() => {
                      logEvent(
                        "Weekly Events",
                        "Know more event Clicked",
                        event.title
                      );
                    }}
                  >
                    {event?.title}
                  </Link>
                </div>
              </li>
            );
          })}
        </ul>
      ) : (
        <span>
          No events scheduled for the week, please check in monthly tab.
        </span>
      )}
    </div>
  );
};

export default Weekly;
