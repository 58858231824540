import React from "react";
import "./Services.css";
import {
  Typography,
  Box,
  FormLabel,
  Button,
  FormControlLabel,
  Checkbox,
  Paper,
  Grid,
} from "@mui/material";

import logo from "../../assets/images/logo.png";
import card1 from "../../assets/images/card1.png";
import card2 from "../../assets/images/tap-to-pay.png";

import List from "@mui/material/List";
import { ListItemButton, ListItemText } from "@mui/material";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Link } from "react-router-dom";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const ExpressDonation = () => {
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const [selectedOnetime, setSelectedOnetime] = React.useState(0);
  const handleListItem2Click = (event, onetime) => {
    setSelectedOnetime(onetime);
  };

  const [service, setService] = React.useState("");
  const handleChange = (event) => {
    setService(event.target.value);
  };

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [value, setValue] = React.useState(0);
  const handleChange2 = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, textAlign: "center" }}
          id="customized-dialog-title"
        >
          Choose your payment method
        </DialogTitle>

        <DialogContent>
          <Grid
            sx={{
              display: "flex",
              flexWrap: "wrap",
              "& > :not(style)": {
                m: 3,
                width: 138,
                height: 138,
                textAlign: "center",
                p: 2,
              },
            }}
          >
            <Paper square>
              <img src={card1} alt="logo" loading="lazy" />
              <Typography variant="h6" className="pop-ttl">
                Credit/Debit Cards
              </Typography>
            </Paper>
            <Typography variant="body1">(or)</Typography>
            <Paper square>
              <img src={card2} alt="logo" loading="lazy" />
              <Typography variant="h6" className="pop-ttl">
                Tap to Pay
              </Typography>
            </Paper>
          </Grid>
        </DialogContent>
      </BootstrapDialog>

      <Grid sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid size={2} sx={{ p: 1 }}>
            <img src={logo} alt="logo" loading="lazy" className="logo" />
          </Grid>
          <Grid size={10}>
            <List
              component="nav"
              aria-label="secondary"
              sx={{ display: "flex", textAlign: "center", m: 3 }}
            >
              <ListItemButton
                selected={selectedIndex === 1}
                onClick={(event) => handleListItemClick(event, 1)}
              >
                <ListItemText primary="Express Booking" />
              </ListItemButton>
              {/* <ListItemButton
                selected={selectedIndex === 2}
                onClick={(event) => handleListItemClick(event, 2)}
              >
                <ListItemText primary="Daily Services" />
              </ListItemButton>
              <ListItemButton
                selected={selectedIndex === 3}
                onClick={(event) => handleListItemClick(event, 3)}
              >
                <ListItemText primary="Personal Services" />
              </ListItemButton>
              <ListItemButton
                selected={selectedIndex === 4}
                onClick={(event) => handleListItemClick(event, 4)}
              >
                <ListItemText primary="Donations" />
              </ListItemButton>
              <ListItemButton
                selected={selectedIndex === 5}
                onClick={(event) => handleListItemClick(event, 5)}
              >
                <ListItemText primary="Weekly" />
              </ListItemButton>
              <ListItemButton
                selected={selectedIndex === 6}
                onClick={(event) => handleListItemClick(event, 6)}
              >
                <ListItemText primary="Monthly" />
              </ListItemButton> */}
            </List>
          </Grid>
          <Grid>
            <Typography variant="h4" className="donate-ttl">
              Donate Today !
            </Typography>
            <Grid sx={{ mt: 1, paddingLeft: 2 }}>
              <Typography variant="h6" className="ser-sub-ttl">
                Your Donation
              </Typography>

              <Box sx={{ width: "100%", marginLeft: 4, mt: 1 }}>
                <Box>
                  <Tabs
                    value={value}
                    onChange={handleChange2}
                    aria-label="basic tabs example"
                  >
                    <Tab label="One-time Donation" />
                    {/* <Tab label="Monthly Donation" /> */}
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <List
                    component="nav"
                    aria-label="secondary"
                    sx={{
                      display: "flex",
                      textAlign: "center",
                      paddingLeft: 2,
                    }}
                  >
                    <ListItemButton
                      className="price-btn"
                      selected={selectedOnetime === 1}
                      onClick={(event) => handleListItem2Click(event, 1)}
                    >
                      <ListItemText primary="$ 50" />
                    </ListItemButton>
                    <ListItemButton
                      className="price-btn"
                      selected={selectedOnetime === 2}
                      onClick={(event) => handleListItem2Click(event, 2)}
                    >
                      <ListItemText primary="$ 100" />
                    </ListItemButton>
                    <ListItemButton
                      className="price-btn"
                      selected={selectedOnetime === 3}
                      onClick={(event) => handleListItem2Click(event, 3)}
                    >
                      <ListItemText primary="$ 150" />
                    </ListItemButton>
                    <ListItemButton
                      className="price-btn"
                      selected={selectedOnetime === 4}
                      onClick={(event) => handleListItem2Click(event, 4)}
                    >
                      <ListItemText primary="$ 200" />
                    </ListItemButton>
                    <ListItemButton
                      className="price-btn"
                      selected={selectedOnetime === 5}
                      onClick={(event) => handleListItem2Click(event, 5)}
                    >
                      <ListItemText primary="$ 250" />
                    </ListItemButton>

                    <FormControl sx={{ width: 160 }}>
                      <TextField
                        type="text"
                        name="Others"
                        placeholder="Others"
                      />
                    </FormControl>
                  </List>
                  <Grid sx={{ display: "flex", mt: 1, paddingLeft: 3 }}>
                    <Typography variant="body1" className="ser-amt">
                      Amount :
                    </Typography>
                    <Typography variant="h6" className="dnt-sub-ttl ser-price">
                      $ 150
                    </Typography>
                  </Grid>
                  <Grid sx={{ display: "flex", mt: 1, paddingLeft: 3 }}>
                    <Typography variant="body1" className="ser-amt p-pad">
                      Purpose :
                    </Typography>
                    <Box sx={{ minWidth: 320 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Select Service
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={service}
                          label="Select Service"
                          onChange={handleChange}
                        >
                          <MenuItem value={1}>SatyaNarayan Puja </MenuItem>
                          <MenuItem value={2}>Navgraha Puja</MenuItem>
                          <MenuItem value={3}>Mata Chunnari</MenuItem>
                          <MenuItem value={4}>Pushpa Seva</MenuItem>
                          <MenuItem value={5}>Thali Puja</MenuItem>
                          <MenuItem value={6}>Rudrabhishekam</MenuItem>
                          <MenuItem value={7}>Havan</MenuItem>
                          <MenuItem value={8}>Akhand Ramayan Path</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  Monthly Donation
                </CustomTabPanel>
              </Box>

              <Grid sx={{ mt: 2, paddingLeft: 2 }}>
                <Typography variant="h4" className="ser-sub-ttl">
                  Your Information
                </Typography>
                <Grid sx={{ display: "flex", mt: 2 }}>
                  <FormControl>
                    <FormLabel htmlFor="fname">First Name</FormLabel>
                    <TextField
                      id="fname"
                      type="text"
                      name="fname"
                      value={"Naveen"}
                      placeholder="First Name"
                      autoComplete="fname"
                      autoFocus
                      required
                      variant="outlined"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="lname">Last Name</FormLabel>
                    <TextField
                      id="lname"
                      type="text"
                      value={"Gupta"}
                      name="lname"
                      placeholder="Last Name"
                      autoComplete="lname"
                      autoFocus
                      required
                      variant="outlined"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel htmlFor="email">Email</FormLabel>
                    <TextField
                      id="email"
                      type="email"
                      value={"naveengupta@gmail.com"}
                      name="email"
                      placeholder="Email"
                      autoComplete="email"
                      autoFocus
                      required
                      fullWidth
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid sx={{ mt: 3, paddingLeft: 2 }}>
                <Typography variant="h4" className="ser-sub-ttl">
                  Payment Method
                </Typography>
                <Grid
                  sx={{
                    justifyContent: "flex-start",
                    mt: 2,
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    className="paymode-btn"
                  >
                    Credit Card
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    className="paymode-btn"
                  >
                    Google Pay
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    className="paymode-btn"
                  >
                    Apple Pay
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <Grid sx={{ display: "inline-grid", paddingLeft: 2 }}>
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Subscribe Newsletter"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Get notification from temple via Email"
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Save the information for future use"
            /> */}
          </Grid>
          <Grid sx={{ paddingRight: 2 }}>
            <Button
              type="submit"
              variant="contained"
              className="btn"
              component={Link}
              to="/kiosk/recipt"
            >
              Proceed to Pay
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ExpressDonation;
