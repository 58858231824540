import React from "react";
import "./Home.css";
import { Typography, Box, Card } from "@mui/material";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      <section className="home-container">
        <div className="image-container"></div>
        <div className="content-container">
          <Box
            sx={{
              display: "flex",
              minHeight: "90vh",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Card sx={{ p: 5, width: 540 }}>
              <Typography variant="h6" className="sub-ttl">
                Welcome to
              </Typography>
              <Typography variant="h4" className="ttl">
                Durga temple of Virginia
              </Typography>
              <Typography variant="body1" className="p2">
                Please enter your details to proceed and make offerings.
              </Typography>
              <Box
                component="form"
                noValidate
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: 1,
                  mt: 4,
                }}
              >
                <FormControl>
                  <FormLabel htmlFor="mobile">Mobile Number</FormLabel>
                  <TextField
                    id="mobile"
                    type="number"
                    value={"123456789"}
                    name="mobile"
                    placeholder="Mobile Number"
                    autoComplete="mobile"
                    autoFocus
                    required
                    fullWidth
                    variant="outlined"
                  />
                </FormControl>
                <Divider>or</Divider>
                <FormControl>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <TextField
                    id="email"
                    type="email"
                    value={"naveengupta@gmail.com"}
                    name="email"
                    placeholder="Enter Email..."
                    autoComplete="email"
                    autoFocus
                    required
                    fullWidth
                    variant="outlined"
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 3,
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  className="btn"
                  component={Link}
                  to="/kiosk/expressDonation"
                >
                  Next
                </Button>

                <Button
                  type="submit"
                  variant="contained"
                  className="btn"
                  component={Link}
                  to="/kiosk/expressDonation"
                >
                  Express Donation
                </Button>
              </Box>
            </Card>
          </Box>
        </div>
      </section>
    </>
  );
};

export default Home;
