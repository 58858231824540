import React, { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  Link,
  Typography,
  Table,
} from "@mui/material";

import "./messages.css";
import { useSearchParams, useNavigate } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import { apiEndPoints } from "../../ApiServices/config";
import { postApi } from "../../ApiServices/api";
import { useDispatch } from "react-redux";
import moment from "moment";
import "../checkAvailability/Checkavailability.css";
import { updateCart } from "../../actions";
export default function Payment() {
  const orderId = localStorage.getItem("orderId");
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const payeeDetails = orderDetails && JSON.parse(orderDetails?.payout);
  const devoteeData = JSON.parse(localStorage.getItem("devoteeData"));

  useEffect(() => {
    if (orderId) {
      getOrderDetails();
    }
  }, [orderId]);

  const animationProps = useSpring({
    to: async (next) => {
      while (processing) {
        await next({ opacity: 1, transform: "translateY(0%)" });
        await next({ opacity: 0.5, transform: "translateY(-10%)" });
        await next({ opacity: 1, transform: "translateY(0%)" });
      }
    },
    from: { opacity: 0, transform: "translateY(-20%)" },
  });

  const getOrderDetails = async () => {
    try {
      const response = await postApi(apiEndPoints.getOrderDetails, {
        order_id: orderId,
      });

      const orderStatus = response.data.data.order_status;

      if (orderStatus !== "paid") {
        setProcessing(true);
        setOrderDetails(response.data.data);
        setTimeout(getOrderDetails, 3000);
        console.log("response.data.data :- ", response.data.data);
      } else {
        setProcessing(false);
        setOrderDetails(response.data.data);
        dispatch(updateCart());
        console.log("response.data.data 11 :- ", response.data.data);
      }
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  return (
    <section className="w-100">
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">Payment Status</h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Payment Status</li>
        </ol>
      </div>

      <div className="row mb-5 ">
        <Container component="main" maxWidth="sm">
          <Box component="form" noValidate sx={{ mt: 3 }} align="center">
            {searchParams.get("success") ? (
              <FormControl>
                {orderDetails &&
                orderDetails?.order_status === "paid" &&
                !processing ? (
                  orderDetails?.items[0]?.items?.rawData?.type === 3 ? (
                    <div className="container py-5">
                      <div className="row">
                        <Container component="main" maxWidth="sm">
                          <Box
                            component="form"
                            noValidate
                            sx={{ mt: 3 }}
                            align="center"
                            className="message-bg p-5"
                          >
                            <FormControl>
                              <FormLabel
                                id="demo-row-radio-buttons-group-label"
                                className="mt-3 sent-color"
                              >
                                Your payment was successfull !!
                              </FormLabel>
                              <Typography
                                variant="body1"
                                className="mt-1"
                                align="center"
                              ></Typography>
                              <Typography className="mt-1 mb-4 p-content-color">
                                Your payment is done Temple Operations manager
                                will check and confirm the booking and get back
                                to you by email or phone.
                              </Typography>
                              <Grid container className="btn-align">
                                <Button
                                  className="cont-btn"
                                  type="submit"
                                  variant="contained"
                                  sx={{ mt: 3, mb: 2, p: 2 }}
                                  onClick={() => navigate("/")}
                                >
                                  Back
                                </Button>
                              </Grid>
                            </FormControl>
                            <CssBaseline />
                            <Box
                              sx={{
                                marginTop: 2,
                              }}
                            ></Box>
                          </Box>
                        </Container>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="container py-5">
                        <div className="row d-flex justify-content-center align-items-center">
                          <div className="receipt-bg">
                            <div className="d-flex justify-content-between mb-5 header-bdr">
                              <div>
                                <div className="logo">
                                  <img src="./images/logo.png" alt="logo" />
                                </div>
                              </div>
                              <div className="receipt-header">
                                <h2>Durga Temple</h2>
                                <p>(A tax exempt non-profit organization)</p>
                                <h3>
                                  8400 Durga Place, Fairfax Station, VA, 22039
                                  <br />
                                  Phone: 703-690-9355
                                </h3>
                              </div>
                              <div>
                                <div className="logo2">
                                  <img src="./images/logo2.png" alt="logo" />
                                </div>
                              </div>
                            </div>
                            <p>
                              Dear{" "}
                              {payeeDetails?.sankalpmembers
                                ? `${payeeDetails?.sankalpmembers[0]?.first_name} ${payeeDetails?.sankalpmembers[0]?.last_name}`
                                : `${payeeDetails?.first_name} ${payeeDetails?.last_name}`}
                              ,
                            </p>
                            <p>
                              Thank you for ordering Puja/Donation. Your Order
                              Number is
                              <strong> {orderDetails?.order_id}</strong>.
                            </p>
                            <Table responsive striped className="trustee">
                              <thead>
                                <tr>
                                  <th>Item</th>
                                  <th>Date</th>
                                  <th>Qty</th>
                                  <th>Unit Price</th>
                                  <th>Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                {orderDetails?.items.map((service, index) => (
                                  <tr>
                                    <td>
                                      {service?.items?.name}
                                      {/* <span>Book online - attend in person</span> */}
                                    </td>
                                    <td>
                                      {service?.item?.type == 5
                                        ? moment(service?.items?.date)
                                            .tz("America/New_York")
                                            .format("M/DD/YYYY")
                                        : moment(service?.items?.date)
                                            .utc()
                                            .format("M/DD/YYYY")}
                                    </td>
                                    <td>{service?.items?.quantity}</td>
                                    <td>
                                      $
                                      {Number(
                                        service?.items?.price
                                      ).toLocaleString("en-US")}
                                    </td>
                                    <td>
                                      $
                                      {(
                                        service?.items?.price *
                                        service?.items?.quantity
                                      ).toLocaleString("en-US")}
                                    </td>
                                  </tr>
                                ))}

                                <tr>
                                  <td colspan="5" className="total-sum">
                                    Total Amount : $
                                    {Number(
                                      orderDetails?.amount
                                    ).toLocaleString("en-US")}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                            <p className="mt-4">
                              <strong>Attention:</strong> If the service booked
                              allows in person attendance, Please carry copy of
                              email / printed copy of the receipt to the temple
                            </p>
                            <p>
                              If you have any questions with your order, please
                              contact &nbsp;
                              <Link to="mailto:contact@durgatemple.org">
                                contact@durgatemple.org
                              </Link>
                              .
                            </p>
                            <p>
                              May the blessings of Goddess Durga be with us
                              always
                            </p>
                            <p className="regards">
                              Durga Temple
                              <br />
                              8400 Durga Place, Fairfax Station, VA, 22039
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                ) : (
                  <React.Fragment>
                    <FormLabel
                      id="demo-row-radio-buttons-group-label"
                      className="mt-2 text-info"
                    >
                      Payment in Progress ...
                    </FormLabel>
                    <Typography variant="body1" className="mt-2" align="center">
                      Please wait while we process your payment. Don't press
                      back or refresh the page while processing payment.
                    </Typography>
                    <animated.div style={animationProps}>
                      <Typography
                        variant="body1"
                        className="mt-2"
                        align="center"
                      >
                        Processing...
                      </Typography>
                    </animated.div>
                  </React.Fragment>
                )}
              </FormControl>
            ) : (
              <>
                <React.Fragment>
                  <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    className="mt-2 text-danger"
                  >
                    Payment Unsuccessful!
                  </FormLabel>
                  <Typography variant="body1" className="mt-2" align="center">
                    Your payment was unsuccessful. Please try again.
                  </Typography>
                </React.Fragment>
                {!processing && (
                  <Grid
                    container
                    justifyContent="center"
                    className="btn-align mt-4"
                  >
                    <Button
                      className="cont-btn"
                      variant="contained"
                      onClick={() => navigate("/")}
                    >
                      Back to Home
                    </Button>
                  </Grid>
                )}
              </>
            )}

            <CssBaseline />
            <Box sx={{ marginTop: 2 }}></Box>
          </Box>
        </Container>
      </div>
    </section>
  );
}
