// import { CircularProgress, Grid, IconButton } from "@mui/material";
// import React, { useEffect } from "react";
// import "./gallery.css";
// import { ArrowBack } from "@mui/icons-material";

// import { apiEndPoints } from "../../ApiServices/config";
// import { postApi } from "../../ApiServices/api";

// export default function Videos() {
//   const navigate = useNavigate();
//   const [isLoading, setIsLoading] = React.useState(true);
//   const [galleryVideos, setGalleryVideos] = React.useState([]);
//   const { slug } = useParams();

//   return (
// <>
//   <div className="container-fluid gallery-bg py-5">
//     <div className="container pt-4">
//       <div className="text-center">
//         <h6 className="section-title text-center text-primary text-uppercase title-tl">
//           Temple Videos
//         </h6>
//         <h1 className="mb-5">
//           <span className="text-secundary">Gallery</span>
//         </h1>
//       </div>
//       <IconButton onClick={() => navigate(-1)} className="mb-5">
//         <ArrowBack />
//       </IconButton>

//       <Grid container spacing={2} justifyContent="center">
//         {isLoading ? (
//           <CircularProgress />
//         ) : galleryVideos?.length > 0 ? (
//           galleryVideos.map((video) => {
//             let embedUrl = "";
//             try {
//               const url = new URL(video.url);

//               if (url.hostname.includes("youtube.com")) {
//                 const videoId = url.searchParams.get("v"); // Extract YouTube video ID
//                 if (videoId) {
//                   embedUrl = `https://www.youtube.com/embed/${videoId}`;
//                 } else {
//                   console.warn("Missing YouTube video ID:", video.url);
//                   return null;
//                 }
//               } else if (
//                 url.hostname.includes("facebook.com") &&
//                 url.pathname.includes("/videos/")
//               ) {
//                 const videoId = url.pathname
//                   .split("/videos/")[1]
//                   ?.split("/")[0];
//                 if (videoId) {
//                   embedUrl = `https://www.facebook.com/plugins/video.php?href=${video.url}&show_text=false&width=560&height=314`;
//                 } else {
//                   console.warn("Missing Facebook video ID:", video.url);
//                   return null;
//                 }
//               } else {
//                 console.warn("Unsupported video URL:", video.url);
//                 return null; // Skip unsupported URLs
//               }
//             } catch (error) {
//               console.error("Invalid URL format:", video.url);
//               return null; // Skip invalid URLs
//             }

//             return (
//               <Grid item key={video.url} xs={12} sm={6} md={4}>
//                 <iframe
//                   src={embedUrl}
//                   width="560"
//                   height="314"
//                   scrolling="no"
//                   frameBorder="0"
//                   allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
//                   allowFullScreen={true}
//                   style={{ width: "100%", height: "315px" }}
//                 ></iframe>
//               </Grid>
//             );
//           })
//         ) : (
//           <p
//             style={{
//               textAlign: "center",
//               color: "#555",
//               marginTop: "1rem",
//             }}
//           >
//             Currently, there are no event videos. Please check in the future
//             for all updated event videos.
//           </p>
//         )}
//       </Grid>
//     </div>
//   </div>
// </>
//   );
// }

import "./Videoplaylist.css";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import Card from "@mui/joy/Card";
import CardCover from "@mui/joy/CardCover";
import CardContent from "@mui/joy/CardContent";

import AspectRatio from "@mui/joy/AspectRatio";
import CardOverflow from "@mui/joy/CardOverflow";
import { CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./gallery.css";
import { Link } from "react-router-dom";
import { apiEndPoints, galleryType } from "../../ApiServices/config";
import Masonry from "@mui/lab/Masonry";
import { getApi, postApi } from "../../ApiServices/api";
import { useNavigate, useParams } from "react-router-dom";

function VideoPlaylist() {
  const navigate = useNavigate();
  const [value, setValue] = React.useState("1");
  const [slug, setSlug] = useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [galleryVideos, setGalleryVideos] = React.useState([]);
  const [gallery, setGallery] = useState({
    list: [],
    categories: [],
    festival: [],
    tags: [],
  });

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setSlug("");
  };

  const payload = {
    keyword: "",
    category: "",
    festival: "",
    tags: "",
    order: "asc",
    type: "video",
  };

  const getEvent = async () => {
    const [galleryList, galleryCategories, galleryFestivals, galleryTags] =
      await Promise.all([
        postApi(apiEndPoints.allGallery, payload),
        getApi(apiEndPoints.galleryCategories),
        getApi(apiEndPoints.galleryFestivals),
        getApi(apiEndPoints.galleryTags),
      ]);

    setGallery({
      list: galleryList?.data?.status == 1 ? galleryList?.data?.data : [],
      categories:
        galleryCategories?.data?.status == 1
          ? galleryCategories?.data?.data
          : [],
      festival:
        galleryFestivals?.data?.status == 1 ? galleryFestivals?.data?.data : [],
      tags: galleryTags?.data?.status == 1 ? galleryTags?.data?.data : [],
    });
  };

  const getPhotos = async () => {
    setIsLoading(true);
    const payload = {
      slug: slug,
    };
    const getVideos = await postApi(apiEndPoints.galleryVideos, payload);

    getVideos?.data?.status == 1
      ? setGalleryVideos(getVideos?.data?.data?.videos)
      : setGalleryVideos([]);

    setIsLoading(false);
  };

  useEffect(() => {
    getEvent();
  }, []);

  useEffect(() => {
    getPhotos();
  }, [slug]);

  return (
    <>
      <div className="container-fluid py-5">
        <div className="container pt-4">
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box>
                <TabList
                  sx={{ borderBottom: 1, borderColor: "divider" }}
                  onChange={handleChange}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="secondary tabs example"
                >
                  <Tab label="Videos" value="1" className="tab-btn" />
                  <Tab label="Playlists" value="2" className="tab-btn" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Box
                  component="ul"
                  sx={{ display: "flex", gap: 2, flexWrap: "wrap", p: 0, m: 0 }}
                >
                  Currently there are no events gallery, please check in future
                  for all updated event gallery.
                </Box>
              </TabPanel>
              <TabPanel value="2">
                {slug != "" ? (
                  <>
                    <div className="container-fluid gallery-bg py-5">
                      <div className="container pt-4">
                        <Grid container spacing={2} justifyContent="center">
                          {isLoading ? (
                            <CircularProgress />
                          ) : galleryVideos?.length > 0 ? (
                            galleryVideos.map((video) => {
                              try {
                                const embedUrl = video.url;

                                // Validate the URL to ensure it's a YouTube embed URL
                                const url = new URL(embedUrl);
                                if (
                                  !url.hostname.includes("youtube.com") ||
                                  !url.pathname.startsWith("/embed/")
                                ) {
                                  console.warn(
                                    "Invalid or unsupported YouTube embed URL:",
                                    video.url
                                  );
                                  return null; // Skip invalid URLs
                                }

                                return (
                                  <Grid
                                    item
                                    key={video.url}
                                    xs={12}
                                    sm={6}
                                    md={4}
                                  >
                                    <iframe
                                      src={embedUrl}
                                      width="560"
                                      height="314"
                                      scrolling="no"
                                      frameBorder="0"
                                      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                                      allowFullScreen
                                      style={{ width: "100%", height: "315px" }}
                                    ></iframe>
                                  </Grid>
                                );
                              } catch (error) {
                                console.error(
                                  "Error processing video URL:",
                                  video.url,
                                  "| Error:",
                                  error
                                );
                                return null; // Skip the video if there's an error
                              }
                            })
                          ) : (
                            <p
                              style={{
                                textAlign: "center",
                                color: "#555",
                                marginTop: "1rem",
                              }}
                            >
                              Currently, there are no event videos. Please check
                              in the future for all updated event videos.
                            </p>
                          )}
                        </Grid>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="container-fluid gallery-bg py-5">
                      <div className="container pt-4">
                        <Grid container justifyContent="center">
                          {gallery.list?.length > 0 ? (
                            <Masonry columns={{ xs: 1, sm: 2 }} spacing={2}>
                              {gallery.list?.map((event) => (
                                <Link
                                  key={event.slug}
                                  component="div"
                                  onClick={() => setSlug(event.slug)}
                                >
                                  <div className="gallery-background-image">
                                    <img src={event.image} alt="" />
                                  </div>
                                  <Typography variant="h5" textAlign="center">
                                    {event.name}
                                  </Typography>
                                </Link>
                              ))}
                            </Masonry>
                          ) : (
                            "Currently there are no events gallery, please check in future for all updated event gallery."
                          )}
                        </Grid>
                      </div>
                    </div>
                  </>
                )}
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
    </>
  );
}

export default VideoPlaylist;
