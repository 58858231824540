import React, { useEffect, useState } from "react";
import "./templeTrustees/templetrustees.css";
import { Link } from "react-router-dom";
import { getApi } from "../ApiServices/api";
import { apiEndPoints } from "../ApiServices/config";
// import { Button, Table } from "react-bootstrap";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button } from "@mui/material";
import { IconButton, Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

function PoojaSamagriList() {
  const [copied, setCopied] = React.useState(false);
  const [pujaSamagri, setPujaSamagri] = useState([]);
  const getPujaSamagri = async () => {
    const response = await getApi(apiEndPoints.allPujaSamagri);
    if (response && response.status === 200) {
      setPujaSamagri(response?.data);
    }
  };

  useEffect(() => {
    getPujaSamagri();
  }, []);

  const handleDownload = (url) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.download = "PujaSamagri.pdf";
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching the file:", error);
      });
  };

  const handleCopy = async (url) => {
    try {
      await navigator.clipboard.writeText(url);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      console.error("Failed to copy URL: ", error);
    }
  };

  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">
          Pooja Samagri List
        </h1>
        <ul className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/">Services</Link>
          </li>
          <li className="breadcrumb-item active">Pooja Samagri List</li>
        </ul>
      </div>

      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Pooja
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Samagri List</span>
          </h1>
        </div>

        <TableContainer>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className="table-bdr"
          >
            <TableHead>
              <TableRow>
                <TableCell className="tbl-td" style={{ fontWeight: 600 }}>
                  Name of the Service
                </TableCell>
                <TableCell className="tbl-td" style={{ fontWeight: 600 }}>
                  Category of Service
                </TableCell>
                <TableCell className="tbl-td" style={{ fontWeight: 600 }}>
                  Pooja Samagri List
                </TableCell>
                <TableCell className="tbl-td" style={{ fontWeight: 600 }}>
                  {" "}
                  PDF
                </TableCell>
                <TableCell className="tbl-td" style={{ fontWeight: 600 }}>
                  URL
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pujaSamagri?.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row" className="tbl-td">
                    {row?.servicename}
                  </TableCell>
                  <TableCell className="tbl-td">{row?.category}</TableCell>

                  <TableCell className="tbl-td">{row?.description}</TableCell>
                  <TableCell className="tbl-td">
                    <Button
                      onClick={() => {
                        handleDownload(row?.file);
                      }}
                    >
                      Download PFD
                    </Button>
                  </TableCell>
                  <TableCell className="tbl-td">
                    <Tooltip title={copied ? "Copied!" : "Copy URL"} arrow>
                      <IconButton
                        color="primary"
                        onClick={() => {
                          handleCopy(row.file);
                        }}
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

export default PoojaSamagriList;
