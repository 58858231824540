import {
  Box,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Controller } from "react-hook-form";
import { logEvent } from "../../../utils/analytics";
function ServiceCard(props) {
  const {
    i,
    increaseQuantity,
    decreaseQuantity,
    handleOrderClick,
    selectedDate,
    setSelectedDate,
    quantityCounts,
    type,
    control,
    shouldDisableDate,
  } = props;

  const handleImageClick = (service) => {
    logEvent("Service Interaction", "Image Clicked", service.title);
  };

  const handleDateChange = (date, service) => {
    setSelectedDate((prevDates) => ({
      ...prevDates,
      [service._id]: date,
    }));
    logEvent("Service Interaction", "Date Selected", service.title);
  };

  const handleQuantityChange = (action, service) => {
    logEvent(
      "Service Interaction",
      `Quantity ${action}`,
      `${service.title} (Current Quantity: ${quantityCounts[service._id] || 1})`
    );
  };

  const handlePujaRequest = (service) => {
    logEvent("Service Interaction", "Puja Requested", service.title);
    handleOrderClick(service);
  };

  return (
    <Card>
      <Grid container padding={1} spacing={1}>
        <Grid item xs={12} sx={{ borderRadius: 5 }}>
          {type !== "outSideTemple" ? (
            <Link
              to={`/services/${i.category_slug}/${i.slug}`}
              onClick={() => handleImageClick(i)}
            >
              <img
                style={{
                  width: "100%",
                  height: "280px",
                  borderRadius: 20,
                  padding: 10,
                }}
                src={i.image}
                alt={i.title}
              />
            </Link>
          ) : (
            <img
              style={{
                width: "100%",
                height: "300px",
                borderRadius: 20,
                padding: 10,
              }}
              src={i.image}
              alt={i.title}
            />
          )}
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center", height: 50, mb: 1 }}>
          <h5 className="mb-0 religious-services-title">{i.title}</h5>
        </Grid>

        <Grid item xs={12}>
          <Grid container columnSpacing={1}>
            <Grid item xs={type == "atTemple" ? 8 : 12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Select a date"
                  slotProps={{ textField: { fullWidth: true } }}
                  value={selectedDate[i._id]}
                  onChange={(date) => handleDateChange(date, i)}
                  shouldDisableDate={(date) => shouldDisableDate(date, i)}
                />
              </LocalizationProvider>
            </Grid>

            {type == "atTemple" && (
              <Grid item xs={4}>
                <div className="input-group quantity mx-auto">
                  <div className="input-group-btn">
                    <button
                      className="btn btn-sm btn-primary btn-minus"
                      onClick={() => {
                        decreaseQuantity(i._id);
                        handleQuantityChange("Decreased", i);
                      }}
                    >
                      <i className="fa fa-minus"></i>
                    </button>
                  </div>
                  <input
                    type="text"
                    className="form-control form-control-sm bg-secondary text-center"
                    value={quantityCounts[i._id] || 1}
                  />
                  <div className="input-group-btn">
                    <button
                      className="btn btn-sm btn-primary btn-plus"
                      onClick={() => {
                        increaseQuantity(i._id);
                        handleQuantityChange("Increased", i);
                      }}
                    >
                      <i className="fa fa-plus"></i>
                    </button>
                  </div>
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>

        {type == "outSideTemple" && (
          <Grid item xs={12}>
            <Controller
              control={control}
              name={`items[${i._id}].location`}
              render={({ field }) => (
                <FormControl key={field.value} fullWidth>
                  <InputLabel id={`location-label-${i._id}`}>
                    Location
                  </InputLabel>
                  <Select
                    {...field}
                    labelId={`location-label-${i._id}`}
                    label="Location"
                  >
                    <MenuItem value={"0-50miles"}>0 - 50 miles</MenuItem>
                    <MenuItem value={"50-100miles"}>50 - 100 miles</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={8}>
              <Stack>
                <Button
                  fullWidth={true}
                  onClick={() => {
                    handleOrderClick(i);
                    logEvent(
                      "Cart",
                      "Add to Cart",
                      `${i.title} - $${i.amount}`
                    );
                  }}
                >
                  Request Puja
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={4} sx={{ textAlign: "center" }}>
              <h2 className="religious-subtitle">
                $ {i.amount !== 0 ? i.amount : "-"}
              </h2>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div
            className="service-content-bdr truncate"
            dangerouslySetInnerHTML={{
              __html: i?.description,
            }}
          />
        </Grid>
      </Grid>
    </Card>
  );
}

export default ServiceCard;
