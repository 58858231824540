import "../services/components/religiousservices.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Button } from "react-bootstrap";
import PoojaBasket from "../../components/common/Basket/PoojaBasket";
import { Link } from "react-router-dom";
import moment from "moment";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { Controller } from "react-hook-form";
import { useServicesOutsideTempleFunctions } from "./customeHook";
import ServiceCard from "../../components/common/cards/ServiceCard";

function ServicesOutsideTemple() {
  const {
    serviceData,
    selectedDate,
    setSelectedDate,
    control,
    handleOrderPuja,
  } = useServicesOutsideTempleFunctions();

  const handleOrderClick = (i) => {
    handleOrderPuja(i);
  };
  const shouldDisableDate = (date, i) => {
    const currentDate = moment.tz("America/New_York").startOf("day");
    return date.isBefore(currentDate, "day");
  };
  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">
          Outside Temple Services
        </h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/outsidetemple">Outside Temple Services</Link>
          </li>
        </ol>
      </div>
      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Durga Temple
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Outside Temple Services</span>
          </h1>
        </div>
        <p>
          <strong>Please Note:</strong> The schedule may be changed due to
          special events held in temple on that day.
        </p>
        <Grid container>
          <Grid item xs={12} sm={9} md={9} lg={9}>
            <Grid container spacing={2}>
              {serviceData?.map((i, index) => (
                <Grid
                  key={i._id}
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={4}
                  className="equal-height"
                >
                  <ServiceCard
                    i={i ? i : []}
                    handleOrderClick={handleOrderClick}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    type={"outSideTemple"}
                    control={control}
                    shouldDisableDate={shouldDisableDate}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>

          {/* basket ------------------------------------------- */}
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <PoojaBasket />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default ServicesOutsideTemple;
