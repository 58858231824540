import "./index.css";
import { useParams, useSearchParams } from "react-router-dom";
import { postApi } from "../../ApiServices/api";
import { apiEndPoints } from "../../ApiServices/config";
import toast from "react-hot-toast";
import Message from "./Payment";
import ServicesProductDisplay from "./servicesProductDisplay";
import React, { useEffect, useState } from "react";
import "./index.css";
import moment from "moment";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormHelperText,
  styled,
} from "@mui/material";
import { ArrowForwardIosSharp } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { getApi } from "../../ApiServices/api";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { updateCart } from "../../actions";
import { yupResolver } from "@hookform/resolvers/yup";

// Define yup schema for form validation
const schema = yup.object().shape({
  email: yup.string().email().required("Please Enter your Email"),
  country: yup.string().required("Country is required"),
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  address: yup.string().required("Address is required"),
  city: yup.string().required("City is required"),
});

const CustomAccordion = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const CustomAccordionSummary = styled((props) => (
  <AccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function ServicesPaymentGateway() {
  const [message, setMessage] = useState("");
  const [searchParams] = useSearchParams();
  const [serviceData, setServiceData] = useState();
  const isDevoteeLoggedIn = localStorage.getItem("token");
  const [totalAmount, setTotalAmount] = useState(0);
  const [expanded, setExpanded] = React.useState({});
  const [country, setCountry] = useState();
  const [bookingData, setBookingData] = useState();
  const [devoteeData, setDevoteeData] = useState();
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    const total = bookingData?.reduce(
      (total, item) => total + item.totalCost,
      0
    );
    setTotalAmount(total);
  }, [bookingData]);

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout

    if (searchParams.get("success")) {
      setMessage("Order placed! You will receive an email confirmation.");
      dispatch(updateCart());
    }

    if (searchParams.get("canceled")) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);
  const productData = bookingData?.map((item) => {
    return {
      total: totalAmount,
      items: {
        ...item,
        _id: item._id,
        type: 3,
        type_id: 3,
        name: item.name,
        quantity: 1,
        price: item.totalCost,
        date: item.date,
        cut_off_hours: "",
        cut_off_service_time: "",
        cut_off_type: "",
        location: "",
        booking_config: "",
        row_data: item.rawData,
        page: "payment-gateway/:bookingId",
      },
    };
  });

  const getBookingData = async () => {
    try {
      const response = await postApi(apiEndPoints.getBookingData, {
        category: "rental",
        _id: params.bookingId,
      });
      const devoteeData = await postApi(apiEndPoints.getProfile, {
        user_id: response.data.devoteeId,
      });

      setDevoteeData(devoteeData.data.data);

      if (response.data.data.length > 0) setBookingData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBookingData();
  }, []);
  const onSubmit = async (data) => {
    try {
      // Validate form data using yup schema
      await schema.validate(data);
      if (isDevoteeLoggedIn) {
        data.user_id = await devoteeData?.user_id;
      }

      const response = await postApi(apiEndPoints.stripePayment, {
        productData: productData,
        customerData: data,
        bookingId: params.bookingId,
      });

      if (response?.data?.status === 1) {
        localStorage.setItem("orderId", response.data.orderData._id);
        setTimeout(() => {
          window.location.href = response?.data?.url;
        }, 1000);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.log(error);
    }
  };

  // service product display related data-----------------------------

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded((prev) => ({
      ...prev,
      [panel]: newExpanded ? panel : false,
    }));
  };

  const getCountries = async () => {
    const response = await getApi(apiEndPoints.countries);
    if (response && response.status === 200) {
      setCountry(response?.data?.data);
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  // all form related implementations
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    setError,
    reset,
    trigger,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (isDevoteeLoggedIn && watch("use_for_billing") === true) {
      reset({
        email: devoteeData?.email,
        first_name: devoteeData?.first_name,
        last_name: devoteeData?.last_name,
        country: devoteeData?.country,
        address: devoteeData?.address?.address,
        city: devoteeData?.city,
      });
    }
  }, [isDevoteeLoggedIn, watch("use_for_billing"), reset]);

  const handleEmptyPoojaBasket = () => {
    dispatch(updateCart());
  };

  return (
    <section className="w-100 justify-content-center align-items-center">
      {message ? (
        <Message message={message} serviceData={serviceData} />
      ) : (
        <div className="mb-5">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="d-flex justify-content-center align-items-center"
            style={{ flexDirection: "column" }}
          >
            {/* //service product display-------------------------------------------------------------------  */}
            {/* <ServicesProductDisplay onSubmit={onSubmit} /> */}
            <>
              <div className="container-fluid page-header py-4 breadcrumb-bg">
                <h1 className="text-center display-6 breadcrumb-h1">
                  Payment Details
                </h1>
                <ol className="breadcrumb justify-content-center mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Proceed to Pay</li>
                </ol>
              </div>
              <div className="container py-5">
                <div className="text-center mb-4">
                  <h6 className="section-title text-center text-primary text-uppercase title-tl">
                    Payment
                  </h6>
                  <h1 className="mb-5">
                    <span className="text-secundary">Details</span>
                  </h1>
                </div>

                <div className="row">
                  <div className="col-lg-4 col-md-4">
                    <div className="d-flex justify-content-between">
                      <h5 className="sub-title-h5 mb-2">Customer Details</h5>
                    </div>
                    <form>
                      <FormControl fullWidth>
                        <Controller
                          control={control}
                          name="country"
                          render={({ field, fieldState }) => {
                            return (
                              <FormControl
                                key={field.value}
                                fullWidth
                                error={errors && errors.country ? true : false}
                                className="dropdown-margin-top"
                              >
                                <InputLabel id="demo-simple-select-label">
                                  Select Country
                                </InputLabel>
                                <Select
                                  {...field}
                                  labelId="demo-simple-select-label"
                                  label="Select Country *"
                                  defaultValue={field.value}
                                >
                                  {country?.map((item) => (
                                    <MenuItem key={item._id} value={item._id}>
                                      {item.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {errors && errors.country && (
                                  <FormHelperText error>
                                    {errors &&
                                    errors.country &&
                                    errors.country.message
                                      ? errors.country.message
                                      : ""}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            );
                          }}
                        />
                      </FormControl>
                      <TextField
                        {...register("email")}
                        margin="normal"
                        fullWidth
                        label="Email *"
                        error={errors && errors.email && errors?.email?.message}
                        helperText={
                          errors && errors.email && errors?.email?.message
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        className="mt-2"
                        fullWidth
                        {...register("first_name")}
                        id="outlined-basic"
                        label="First Name"
                        variant="outlined"
                        error={
                          errors &&
                          errors.first_name &&
                          errors.first_name.message
                        }
                        helperText={
                          errors &&
                          errors.first_name &&
                          errors.first_name.message
                            ? errors.first_name.message
                            : ""
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        className="mt-2"
                        {...register("last_name")}
                        fullWidth
                        id="outlined-basic"
                        label="Last Name"
                        variant="outlined"
                        error={
                          errors && errors.last_name && errors.last_name.message
                            ? errors.last_name.message
                            : ""
                        }
                        helperText={
                          errors && errors.last_name && errors.last_name.message
                            ? errors.last_name.message
                            : ""
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        {...register("company_name")}
                        fullWidth
                        id="outlined-basic"
                        label="Company Name"
                        variant="outlined"
                      />
                      <TextField
                        {...register("address")}
                        fullWidth
                        id="outlined-basic"
                        label="Address"
                        variant="outlined"
                        error={
                          errors && errors.address && errors.address.message
                            ? errors.address.message
                            : ""
                        }
                        helperText={
                          errors && errors.address && errors.address.message
                            ? errors.address.message
                            : ""
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        {...register("city")}
                        fullWidth
                        id="outlined-basic"
                        label="City"
                        variant="outlined"
                        error={
                          errors && errors.city && errors.city.message
                            ? errors.city.message
                            : ""
                        }
                        helperText={
                          errors && errors.city && errors.city.message
                            ? errors.city.message
                            : ""
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      {isDevoteeLoggedIn && (
                        <FormControlLabel
                          {...register("use_for_billing")}
                          control={<Checkbox defaultChecked />}
                          label="Use Devoteee Details for billing"
                        />
                      )}
                    </form>
                  </div>
                  <div className="col-lg-8 col-md-8">
                    <div className="container religious-service-product">
                      <div className="d-flex justify-content-between">
                        <h5 className="font-weight-semi-bold mb-2">
                          Payment Details
                        </h5>
                        <div
                          className="empty-btn"
                          onClick={handleEmptyPoojaBasket}
                        >
                          Empty Puja Basket
                        </div>
                      </div>
                      <div className="custom-control custom-checkbox mb-2">
                        {isDevoteeLoggedIn ? (
                          <>
                            {bookingData?.length > 0 &&
                              bookingData?.map((item) => (
                                <>
                                  <CustomAccordion
                                    expanded={
                                      expanded[item._id] == undefined
                                        ? true
                                        : expanded[item._id]
                                    }
                                    onChange={handleChange(item._id)}
                                  >
                                    <CustomAccordionSummary
                                      aria-controls="panel1d-content"
                                      id="panel1d-header"
                                    >
                                      <Typography className="product-title">
                                        {" "}
                                        {item?.rawData?.title}
                                      </Typography>
                                    </CustomAccordionSummary>
                                    <CustomAccordionDetails>
                                      <Typography>
                                        <ul className="payment-detail">
                                          <li>
                                            {item?.rawData?.title}
                                            <span>
                                              $ {item?.rawData?.amount}
                                            </span>
                                          </li>
                                          <li>
                                            Date{" "}
                                            <span>
                                              {" "}
                                              {moment(new Date(item?.date))
                                                .tz("America/New_York")
                                                .format("YYYY-DD-MM")}
                                            </span>
                                          </li>
                                          <li>
                                            Duration in hrs{" "}
                                            <span>{item?.hour} hrs</span>
                                          </li>
                                          <li>
                                            Amount{" "}
                                            <span>
                                              ${" "}
                                              {item?.rawData?.amount +
                                                125 *
                                                  (item?.hour -
                                                    item?.rawData
                                                      ?.amount_hour)}{" "}
                                            </span>
                                          </li>
                                          <li>
                                            Additional Amount{" "}
                                            <span>
                                              ${" "}
                                              {
                                                item?.rawData
                                                  ?.additional_hour_amount
                                              }
                                            </span>
                                          </li>
                                          <li>
                                            Cleaning charge{" "}
                                            <span>
                                              {" "}
                                              $ {item?.rawData?.cleaning_amount}
                                            </span>
                                          </li>
                                          <li className="service-details-book">
                                            Book online - attend in person
                                          </li>
                                        </ul>
                                      </Typography>
                                    </CustomAccordionDetails>
                                  </CustomAccordion>
                                </>
                              ))}
                          </>
                        ) : null}

                        <ul className="detail-list">
                          <li className="service-details-total">
                            Total Amount: <span>$ {totalAmount}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="row mt-5">
                      <div className="col-lg-6 col-md-6">
                        <h5 className="sub-title-h5 mb-2">
                          Puja Basket Totals
                        </h5>
                        <ul className="detail-list">
                          <li className="service-details-total">
                            Cart Subtotal <span>$ {totalAmount}</span>
                          </li>
                          <li className="service-details-total">
                            Services <span>1</span>
                          </li>
                          <li className="service-details-total">
                            Order Total <span>$ {totalAmount}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
            {/* //service product display-------------------------------------------------------------------  */}

            <button className="btn text-white" type="submit">
              Pay Now
            </button>
          </form>
        </div>
      )}
    </section>
  );
}
