import { useEffect, useState } from "react";
import { getApi } from "../../ApiServices/api";
import { apiEndPoints } from "../../ApiServices/config";

export const useServicesAtTempleFunctions = () => {
  const [serviceCategories, setServiceCategories] = useState();
  const getServiceCategories = async () => {
    const response = await getApi(apiEndPoints.getAllServiceCatogryItems);
    if (response && response.status === 200) {
      setServiceCategories(response?.data?.data);
    }
  };

  useEffect(() => {
    getServiceCategories();
  }, []);

  return {
    serviceCategories,
  };
};
