import React, { useEffect, useState } from "react";
import { logEvent } from "../../../../utils/analytics";

const LatestUpdates = ({ annualCalendar, latestNews }) => {
  const [currentNewsIndex, setCurrentNewsIndex] = useState(0);

  useEffect(() => {
    if (!latestNews?.data?.length) return;

    const timer = setTimeout(() => {
      setCurrentNewsIndex((prevIndex) =>
        prevIndex === latestNews.data.length - 1 ? 0 : prevIndex + 1
      );
    }, 20500);

    return () => clearTimeout(timer);
  }, [currentNewsIndex, latestNews?.data?.length]);

  if (!latestNews?.data?.length) {
    return null;
  }

  return (
    <div className="container-fluid py-2">
      <div className="container">
        <div className="row align-items-center">
          <div className="col newscroll">
            <h4 className="newsupdate-top">Latest Update</h4>
          </div>

          <div className="col-6 latestnews marquee">
            {latestNews.data[currentNewsIndex] && (
              <marquee key={latestNews.data[currentNewsIndex]._id}>
                <div className="newsupdate-p newsupdate-top scrolling-font">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: latestNews.data[currentNewsIndex].description,
                    }}
                  />
                </div>
              </marquee>
            )}
          </div>

          <div className="col text-end">
            <a
              className="btn btn-text2"
              href={annualCalendar.data}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                logEvent("Annual Calender", "Annual Calender Clicked");
              }}
            >
              Annual Calendar
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LatestUpdates;
