import React from "react";
import "./spotlight.css";

function Sportlight(props) {
  const { spotlight } = props;

  return (
    <>
      {spotlight?.data && (
        <div className="container-fluid py-5">
          <div className="container py-5">
            <div className="row">
              <div className="col-lg-7">
                <div className="text-center">
                  <h6 className="section-title text-center text-primary text-uppercase title-tl">
                    {spotlight?.data?.title}
                  </h6>
                  <h1 className="mb-3">
                    <span className="text-secundary">
                      {spotlight?.data?.sub_title}
                    </span>
                  </h1>
                </div>

                <div
                  dangerouslySetInnerHTML={{
                    __html: spotlight?.data?.description,
                  }}
                ></div>
              </div>
              {spotlight?.video_url ||
                (spotlight?.data?.image && (
                  <div className="col-lg-5 mt-5">
                    <div className="video-bg text-center py-4">
                      {spotlight?.data?.video_url && (
                        <div
                          className="img-fluid p-5 video-bdr ratio ratio-16x9"
                          dangerouslySetInnerHTML={{
                            __html: spotlight?.data?.video_url,
                          }}
                        />
                      )}
                      {spotlight?.data?.image && (
                        <img
                          className="img-fluid mb-4 mb-lg-0 video-bdr mt-3"
                          src={spotlight?.data?.image}
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Sportlight;
