import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  DatePicker,
  DesktopTimePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/en";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import toast from "react-hot-toast";
import { updateRentCart } from "../../actions";
import TermsAndConditionsDialog from "./TermsAndConditionsDialog";

const timezoneString = "America/New_York";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault(timezoneString);
moment.tz.setDefault(timezoneString);

const validationSchema = yup.object().shape({
  round_banquet_quantity: yup
    .number()
    .typeError("Round Banquet Tables must be a number")
    .max(20, "Round Banquet Tables cannot be more than 20")
    .required("Round Banquet Tables is required"),
});

export default function RentalItem({ item, cartData, purposes }) {
  const rentArr = useSelector((state) => state.rentArr);
  const [hourDiffn, setHourDiffn] = useState();
  const isLoggedIn = localStorage.getItem("token");
  const dispatch = useDispatch();
  const [isTClinkVisited, setIsTCLinkVisited] = useState(false);
  const [isTimeOutside, setIsTimeOutside] = useState(false);

  const formSchema = yup.object().shape({
    purpose: yup.string().required("Purpose is required"),
    number_of_attendees: yup
      .number()
      .typeError("Number of attendees must be a number")
      .positive("Number of attendees must be positive")
      .integer("Number of attendees must be an integer")
      .required("Number of attendees is required")
      .max(
        item?.maximum_capacity,
        `Number of attendees must be less than or equal to ${item?.maximum_capacity}`
      ),
    date: yup.date().required("Date is required"),
    start_time: yup.string().required("Start time is required"),
    end_time: yup.string().required("End time is required"),
    custom_purpose: yup.string().when("purpose", {
      is: "others",
      then: () =>
        yup
          .string()
          .required("This Field is required when event purpose is Others"),
      otherwise: () => yup.string().notRequired(),
    }),
  });

  const [round_banquet_quantity, setRountBanquet] = useState("");
  const [validationError, setValidationError] = useState("");

  const handleChange = (value) => {
    setRountBanquet(value);

    validationSchema
      .validate({ round_banquet_quantity: value })
      .then(() => setValidationError(""))
      .catch((error) => setValidationError(error.message));
  };

  const { watch, control, reset, handleSubmit, setValue, trigger } = useForm({
    resolver: yupResolver(formSchema),
  });

  // useEffect(() => {
  //   if (cartData) {
  //     const { date, end_time, start_time, ...rest } = cartData;
  //     reset({
  //       ...rest,
  //       date: dayjs(date).tz(timezoneString),
  //       end_time: dayjs(end_time).tz(timezoneString),
  //       start_time: dayjs(start_time).tz(timezoneString),
  //     });
  //     setIsTCLinkVisited(true);
  //     setHourDiffn(cartData?.hour);
  //   }
  // }, []);

  async function getHourlyDifference(startTime, endTime) {
    const formattedStartTime = new Date(startTime);
    const formattedEndTime = new Date(endTime);
    const startMoment = moment(formattedStartTime);
    const endMoment = moment(formattedEndTime);
    let difference = endMoment.diff(startMoment, "hours");
    const minutes = endMoment.diff(startMoment, "minutes") % 60;
    if (minutes > 30) {
      difference++;
    }

    return difference;
  }
  const calculateHourDifference = async () => {
    if (watch(`start_time`) && watch(`end_time`)) {
      const difference = await getHourlyDifference(
        watch(`start_time`),
        watch(`end_time`)
      );
      setHourDiffn(difference);
    }
  };
  const handleCalculateTotalAmount = () => {
    const total =
      item?.amount +
      item?.additional_hour_amount *
        (hourDiffn >= Number(item?.amount_hour)
          ? hourDiffn - Number(item?.amount_hour)
          : 0) +
      item?.security_amount +
      item?.cleaning_amount +
      (watch(`is_needed_kitchen`) ? item?.kitchen_charges : 0) +
      (watch(`is_sound_system_needed`) ? item?.sound_system_charges : 0) +
      (watch(`donation_amount`) ? Number(watch(`donation_amount`)) : 0);
    setValue(`total`, total);
    return total;
  };
  function checkSelectedTime(startTime, EndTime) {
    const nineAM = moment().set({ hour: 8, minute: 55 });
    const ninePM = moment().set({ hour: 21, minute: 30 });

    if (startTime.isBefore(nineAM) || EndTime.isAfter(ninePM)) {
      setIsTimeOutside(true);
    } else {
      setIsTimeOutside(false);
    }
  }

  const handleAddtoCart = (data) => {
    const existingCartItem = rentArr.find(
      (cartItem) => cartItem?.rawData?._id === data?._id
    );
    if (existingCartItem) {
      const basketObject = {
        ...data,
        rawData: item,
        _id: item?._id,
        hour: hourDiffn,
      };
      dispatch(updateRentCart(basketObject));
      toast.success("Your Rental is updated!");
    } else {
      const basketObject = {
        ...data,
        rawData: item,
        _id: item?._id,
        hour: hourDiffn,
      };
      dispatch(updateRentCart(basketObject));
      toast.success("Rental has been added to cart!");
    }
    const basketObject = {
      ...data,
      rawData: item,
      _id: item?._id,
      hour: hourDiffn,
    };
    dispatch(updateRentCart(basketObject));
  };
  const onSubmit = (data) => {
    handleAddtoCart(data);
  };

  useEffect(() => {
    calculateHourDifference();
    if (!!watch("start_time") && !!watch("end_time")) {
      checkSelectedTime(watch("start_time"), watch("end_time"));
    }
  }, [watch("start_time"), watch("end_time")]);
  useEffect(() => {
    handleCalculateTotalAmount();
  }, [
    watch(`is_needed_kitchen`),
    watch(`donation_amount`),
    watch(`is_sound_system_needed`),
    watch(`start_time`),
    watch(`end_time`),
    watch(`date`),
  ]);
  const handleTACButton = (state) => {
    setIsTCLinkVisited(state);
  };
  const sortedEventPurpose = purposes?.sort((a, b) => {
    if (a.name === "Others") return 1;
    if (b.name === "Others") return -1;

    return a.name.localeCompare(b.name);
  });

  return (
    <form id={item?._id} onSubmit={handleSubmit(onSubmit)}>
      <div className="row cart-item m-0 mb-4">
        <div className="col-md-4 col-lg-12 col-xl-4 p-0 max-size">
          <div className="position-relative h-100">
            <img
              className="position-absolute w-100 img-height img-fit"
              src={item?.image}
              alt={item?.title}
            />
          </div>
        </div>
        <div className="col-md-8 col-lg-12 col-xl-8 h-100 px-0">
          <div className="p-4">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h5 className="service-title">{item?.title}</h5>
              <small className="me-3">
                Rental max sitting - {item?.maximum_capacity || 250}
              </small>
            </div>

            <div className="d-flex gap-2 justify-content-between mt-2">
              <Controller
                name="purpose"
                control={control}
                defaultValue={
                  sortedEventPurpose && sortedEventPurpose[0]?.value
                }
                render={({ field, fieldState }) => (
                  <FormControl
                    fullWidth
                    error={!!fieldState?.error}
                    key={field.value}
                  >
                    <InputLabel>Event Purpose</InputLabel>
                    <Select {...field} label="Event Purpose">
                      {sortedEventPurpose?.map((item) => (
                        <MenuItem
                          value={item?.value}
                          selected={item?.selected}
                          key={item?._id}
                          {...(item?.selected
                            ? { selected: item?.selected }
                            : {})}
                        >
                          {item?.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {fieldState?.error?.message ? (
                      <FormHelperText error>
                        {fieldState?.error?.message}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                )}
              />
              <Controller
                name="number_of_attendees"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Number of people attending"
                    helperText={fieldState?.error?.message}
                    error={!!fieldState?.error}
                    onChange={(e) => {
                      field.onChange(e);
                      trigger("number_of_attendees");
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        marginBottom: 0,
                      },
                    }}
                  />
                )}
              />
            </div>
            {watch(`purpose`) === "others" ? (
              <Controller
                name="custom_purpose"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Enter Purpose"
                    helperText={fieldState?.error?.message}
                    error={!!fieldState?.error}
                    sx={{
                      "& .MuiInputBase-root": {
                        marginBottom: 0,
                      },
                    }}
                  />
                )}
              />
            ) : null}
            <small className="me-3 pe-3 additional mt-1">
              <i className="fa fa-exclamation-circle text-primary me-2 additional"></i>
              Cleaning - ${item.cleaning_amount}
            </small>
            <div className="d-flex justify-content-between mb-2 mt-2">
              <div className="religious-subtitle w-100 m-0">
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="en"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name={`date`}
                        render={({ field, fieldState }) => (
                          <>
                            <DatePicker
                              {...field}
                              label="Select a Date"
                              disablePast
                              timezone={timezoneString}
                              formatDensity="spacious"
                              inputFormat="MM/dd/yyyy" // Format for the input field
                              views={["year", "month", "day"]}
                              slotProps={{
                                textField: {
                                  fullWidth: true,
                                  helperText: fieldState?.error?.message,
                                  error: !!fieldState?.error,
                                },
                              }}
                            />
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        control={control}
                        name={`start_time`}
                        render={({ field, fieldState }) => {
                          return (
                            <DesktopTimePicker
                              {...field}
                              key={field.value}
                              // disablePast
                              closeOnSelect={false}
                              label="Start Time"
                              timezone={timezoneString}
                              // maxTime={watch("end_time")}
                              slotProps={{
                                textField: {
                                  fullWidth: true,
                                  helperText: fieldState?.error?.message,
                                  error: !!fieldState?.error,
                                },
                              }}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        control={control}
                        name="end_time"
                        render={({ field, fieldState }) => (
                          <DesktopTimePicker
                            {...field}
                            key={field.value}
                            // disablePast
                            closeOnSelect={false}
                            label="End Time"
                            timezone={timezoneString}
                            // minTime={watch("start_time")}
                            slotProps={{
                              textField: {
                                fullWidth: true,
                                helperText: fieldState?.error?.message,
                                error: !!fieldState?.error,
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </LocalizationProvider>
              </div>
            </div>
            {isTimeOutside && (
              <small>
                *Temple Hall is available from 9am to 9PM only due to insurance
                restrictions, Temple manager will check possibility and get back
                to you
              </small>
            )}

            <div className="d-flex m-2">
              <small className="border-end me-3 pe-3 additional">
                <i className="fa fa-clock-o text-primary me-2 additional"></i>
                Base Rent ( {item.amount_hour} hrs. ) - Max ${item.amount}
              </small>
              <small className="me-3 pe-3 additional">
                <i className="fa fa-clock-o text-primary me-2 additional"></i>
                Additional hrs. @ {item.additional_hour_amount}/hr
              </small>
            </div>

            <div className="d-flex justify-content-between">
              <p className="p-text mt-2">Hall rental ( Base Rent )</p>
              <p className="p-text price-right mt-2">${item.amount}</p>
            </div>
            {item?.additional_hour_amount * (hourDiffn - item?.amount_hour) >
              0 && (
              <div className="d-flex justify-content-between">
                <p className="p-text mt-2">Additional hrs amount</p>
                <p className="p-text price-right mt-2">
                  $
                  {item?.additional_hour_amount *
                    (hourDiffn - item?.amount_hour)}
                </p>
              </div>
            )}
            <hr className="mb-0 mt-2" />
            <div className="d-flex justify-content-between ">
              <p className="p-text ">Hall rental for {hourDiffn || "-"} hrs.</p>
              <p className="p-text price-right">
                $
                {hourDiffn > item?.amount_hour
                  ? item.amount +
                    item?.additional_hour_amount *
                      (hourDiffn - item?.amount_hour)
                  : hourDiffn > 0 && hourDiffn <= item?.amount_hour
                  ? item.amount
                  : 0}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="p-text mt-2">
                Security Deposit(Refundable)
                <span>* Deduction applies for property damage</span>
              </p>

              <p className="p-text price-right mt-3">${item.security_amount}</p>
            </div>

            <p className="text-body mb-0 mt-4">
              <p className="p-text">Additional booking options</p>
              <div className="d-flex justify-content-between mrgn-btm2">
                <Controller
                  name="is_needed_kitchen"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      {...field}
                      key={field.value}
                      checked={field.value}
                      className="label-list"
                      control={<Checkbox />}
                      label="Kitchen Use with Hall (For food warming only)"
                    />
                  )}
                />
                <p className="p-text price-right">
                  ${watch(`is_needed_kitchen`) ? item.kitchen_charges : 0}
                </p>
              </div>
              <div className="d-flex justify-content-between mrgn-btm2">
                <Controller
                  name="is_sound_system_needed"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      {...field}
                      key={field.value}
                      checked={field.value}
                      className="label-list"
                      control={<Checkbox />}
                      label="Audio Video Sound System"
                    />
                  )}
                />
                <p className="p-text price-right">
                  $
                  {watch(`is_sound_system_needed`)
                    ? item.sound_system_charges
                    : 0}
                </p>
              </div>
              <div className="d-flex justify-content-between mrgn-btm2">
                <FormGroup>
                  <Controller
                    name="is_needed_round_banquet_table"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        {...field}
                        key={field.value}
                        checked={field.value}
                        className="label-list"
                        control={<Checkbox />}
                        label="Round Banquet Tables (20 max) 8-10 person per table"
                      />
                    )}
                  />

                  {watch("is_needed_round_banquet_table") ? (
                    <div className="mt-3">
                      <Controller
                        name="round_banquet_quantity"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            type="number"
                            className="me-3"
                            label="Enter Quantity"
                            value={round_banquet_quantity}
                            onChange={(e) => handleChange(e.target.value)}
                            error={!!validationError}
                            helperText={validationError}
                          />
                        )}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </FormGroup>
                <p className="p-text price-right">-</p>
              </div>
            </p>

            <div className="mt-4">
              <div className="d-flex justify-content-between mrgn-btm2">
                <FormGroup>
                  <Controller
                    name="is_needed_preparation"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        {...field}
                        key={field.value}
                        checked={field.value}
                        control={<Checkbox />}
                        label="Preparation (setup and removal of flower decoration, music or setup)"
                      />
                    )}
                  />

                  {watch("is_needed_preparation") ? (
                    <div className="mt-1">
                      <div className="d-flex justify-content-between mb-3">
                        <div className="religious-subtitle d-flex gap-1">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Controller
                              control={control}
                              name={`preparation_date`}
                              render={({ field }) => {
                                return (
                                  <>
                                    <DatePicker
                                      {...field}
                                      disablePast
                                      label="Select a Date"
                                    />
                                  </>
                                );
                              }}
                            />
                            <Controller
                              control={control}
                              name={`preparation_start_time`}
                              render={({ field }) => {
                                return (
                                  <TimePicker
                                    {...field}
                                    disablePast
                                    label="Start Time"
                                  />
                                );
                              }}
                            />
                            <Controller
                              control={control}
                              name={`preparation_end_time`}
                              render={({ field }) => {
                                return (
                                  <TimePicker
                                    {...field}
                                    disablePast
                                    label="End Time"
                                  />
                                );
                              }}
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </FormGroup>
              </div>
            </div>
            {/* donation */}
            <div className="mt-4">
              <div className="d-flex justify-content-between mrgn-btm2">
                <FormGroup>
                  <Controller
                    name="is_donated"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        {...field}
                        key={field.value}
                        checked={field.value}
                        control={<Checkbox />}
                        label="Donation to Durga Temple"
                      />
                    )}
                  />

                  {watch("is_donated") ? (
                    <div className="mt-3">
                      <Controller
                        name="donation_amount"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            type="number"
                            className="me-3"
                            label="Donation Amount"
                          />
                        )}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </FormGroup>
                <p className="p-text price-right ">
                  ${watch("is_donated") ? watch(`donation_amount`) : 0 || 0}
                </p>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between p-3 border-top border-bottom">
            {/* <Button
              variant="contained"
              size="medium"
              className="estimate-btn"
              onClick={() => handleCalculateTotalAmount(item)}
            >
              Show Estimated Cost
            </Button> */}
            <p className="m-0 p-0">Estimated Cost</p>
            <p className="p-text price-right m-0 p-0">${watch("total")}</p>
          </div>

          <div className="d-flex justify-content-between  mt-auto p-3">
            <div>
              <p className="p-text">Rental Terms & Conditions</p>

              <TermsAndConditionsDialog handleTACButton={handleTACButton} />
            </div>
          </div>
          <div
            className="d-flex justify-content-end mx-3"
            onClick={() => {
              if (isTClinkVisited == false) {
                toast.error("Please Read the Terms and Conditions");
              }
            }}
          >
            <button
              className="btn btn2 btn-dark rounded rent-btn"
              type="submit"
              disabled={!isTClinkVisited}
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
