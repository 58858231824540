import React from "react";
import { useParams } from "react-router-dom";
import WeeklyServices from "./components/WeeklyServices";
import AnnualServices from "./components/AnnualServices";
import MonthlyServices from "./components/MonthlyServices";
import SpecialServices from "./components/SpecialServices";
import VahanaSevas from "./components/VahanSevasServices";
import PersonalServices from "./components/PersonalServices";
import RecurringServices from "./components/RecurringServices";
import ReligiousServices from "./components/ReligiousServices";

function Services() {
  const { slug } = useParams();

  const renderServiceComponent = () => {
    switch (slug) {
      case "daily":
        return <ReligiousServices />;
      case "weekly":
        return <WeeklyServices />;
      case "monthly":
        return <MonthlyServices />;
      case "annual":
        return <AnnualServices />;
      case "personal":
        return <PersonalServices />;
      case "special":
        return <SpecialServices />;
      case "recurring":
        return <RecurringServices />;
      case "vahana_sevas":
        return <VahanaSevas />;
    }
  };

  return <>{renderServiceComponent()}</>;
}

export default Services;
