import React from "react";
import "./header.css";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailIcon from "@mui/icons-material/Mail";
import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import LoginIcon from "@mui/icons-material/Login";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useLoginFunctions } from "./CustomHook";

function Topheader() {
  const {
    hasToken,
    showDropdown,
    devoteeData,
    dropdownRef,
    currentUrlPath,
    toggleDropdown,
    handleLogout,
  } = useLoginFunctions();
  return (
    <>
      <div className="container-fluid top-bg px-5 d-none d-lg-block">
        <div className="container">
          <div className="row gx-0 ">
            <div className="col-lg-6 text-center text-lg-start mb-lg-0">
              <div className="d-flex flex-wrap">
                <Link to="/" className="text-light me-4">
                  <LocationOnIcon className="top-icon" /> 8400 Durga Place,
                  Fairfax Station, VA, 22039
                </Link>
                <Link to="/" className="text-light me-0">
                  <MailIcon className="top-icon" /> contact@durgatemple.org
                </Link>
              </div>
            </div>
            <div className="col-lg-3 text-right text-lg-end">
              <ul className="footer-icon-list">
                <li>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.youtube.com/@DurgaTempleLive/streams"
                  >
                    <YouTubeIcon />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/DurgaTemple"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <FacebookIcon />
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.instagram.com/durgatemple/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <InstagramIcon />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 text-center text-lg-end">
              {hasToken ? (
                <div className="d-flex align-items-center justify-content-end">
                  <div className="dropdown" ref={dropdownRef}>
                    <button
                      className="btn btn-secondary dropdown-toggle text-light"
                      type="button"
                      onClick={toggleDropdown}
                    >
                      <AccountCircleIcon className="top-icon" />{" "}
                      {devoteeData?.first_name}
                    </button>
                    {showDropdown && (
                      <ul
                        className="dropdown-menu show"
                        aria-labelledby="dropdownMenuButton"
                        style={{ zIndex: 2000 }}
                      >
                        <li>
                          <Link className="dropdown-item" to="/">
                            Dashboard
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/profile">
                            Profile
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/changepassword">
                            Change Password
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/report">
                            Report
                          </Link>
                        </li>

                        <li>
                          <button
                            className="dropdown-item"
                            onClick={handleLogout}
                          >
                            Logout
                          </button>
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              ) : (
                <div className="d-flex align-items-center justify-content-end">
                  <Link
                    to={`/login?redirect=${currentUrlPath}`}
                    className="text-light me-0"
                  >
                    <LoginIcon className="top-icon" /> Login
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Topheader;
