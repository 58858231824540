import React, { useEffect, useState } from "react";
import "../../../pages/services/components/religiousservices.css";
import { Button } from "react-bootstrap";
import moment from "moment";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useSelector, useDispatch } from "react-redux";
import { updateCart, removeFromCart } from "../../../actions";
import { Link, useNavigate } from "react-router-dom";
import { MenuItem, Select, TextField } from "@mui/material";
import { getApi } from "../../../ApiServices/api";
import { apiEndPoints } from "../../../ApiServices/config";
import { toast } from "react-hot-toast";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import ConsentDialog from "../ConsentDialog";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function PoojaBasket() {
  const navigate = useNavigate();
  const [makeDonationChecked, setMakeDonationChecked] = useState(false);
  const cartArr = useSelector((state) => state.cartArr);
  const [totalAmount, setTotalAmount] = useState(0);
  const [expanded, setExpanded] = React.useState({});
  const [donationData, setDonationsData] = useState();
  const [donationItem, setDonationItem] = useState();
  const [donationAmount, setDonationAmount] = useState();
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    const total = cartArr.reduce(
      (totalAmount, item) => totalAmount + item.amount * (item.quantity || 1),
      0
    );
    setTotalAmount(total);
  }, [cartArr]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded((prev) => ({
      ...prev,
      [panel]: newExpanded ? panel : false,
    }));
  };

  // Empty Pooja Basket
  const handleEmptyPoojaBasket = () => {
    dispatch(updateCart());
  };

  const handleProceedToPay = () => {
    if (cartArr.length === 0) {
      toast.error(
        "Puja basket is empty, add services/donations before payment",
        {}
      );
    } else {
      navigate("/checkout");
    }
  };

  const getAllDonations = async () => {
    const response = await getApi(apiEndPoints.getAllDonations);
    if (response && response.status === 200) {
      setDonationsData(response?.data?.data);
      setDonationItem(response?.data?.data[0]);
    }
  };

  useEffect(() => {
    getAllDonations();
  }, []);

  const handleAddDonations = () => {
    if (Number(donationAmount) === 0) {
      toast.error("Donation amount must be greater than 0");
    } else if (Number(donationAmount) < donationItem?.amount) {
      toast.error(
        "Donation amount must be greater than or equal to donation minimum amount"
      );
    } else {
      const existingCartItem = cartArr.find(
        (cartItem) => cartItem._id === donationItem._id
      );

      if (existingCartItem) {
        const updatedItem = {
          ...existingCartItem,
          amount: donationAmount,
          type_id: 5,
          location: "",
          page: "services/donation",
        };
        dispatch(updateCart(updatedItem));
        toast.success("Your puja/donation is updated");
        setDonationAmount(0);
        setDonationItem(donationData[0]);
        setMakeDonationChecked(false);
      } else {
        const newItem = {
          ...donationItem,
          amount: donationAmount,
          type_id: 5,
          location: "",
          page: "services/donation",
        };
        dispatch(updateCart(newItem));

        setDonationAmount(0);
        setDonationItem(donationData[0]);
        setMakeDonationChecked(false);
      }
    }
  };

  const handleChildrenRemove = (item) => {
    dispatch(removeFromCart(item._id));
    toast.success("Item removed from the Basket");
  };

  const handleInputChange = (e) => {
    const input = e.target.value;

    const regex = /^[0-9]*\.?[0-9]*$/;

    if (regex.test(input)) {
      setDonationAmount(input);
      setError("");
    } else {
      setDonationAmount(input);
      setError("Only numeric values are allowed (no symbols or letters).");
    }
  };
  return (
    <div>
      <div className="border-bottom mb-4 pb-4 details-bg religious-service-product">
        <div className="d-flex justify-content-between mt-4">
          <h5 className="font-weight-semi-bold mb-3">Details</h5>
          <div className="empty-btn" onClick={handleEmptyPoojaBasket}>
            Empty Basket
          </div>
        </div>
        <div className="custom-control custom-checkbox mb-3">
          <>
            {cartArr.length > 0 &&
              cartArr?.map((item, index) => (
                <>
                  <Accordion
                    expanded={
                      expanded[item._id] == undefined
                        ? true
                        : expanded[item._id]
                    }
                    onChange={handleChange(item._id)}
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                    >
                      <Table aria-label="simple table">
                        <TableBody>
                          <TableRow>
                            <TableCell className="td-padding td-border-btm">
                              <Typography>{item.title}</Typography>
                            </TableCell>
                            <TableCell className="price bld td-padding td-border-btm">
                              <ConsentDialog
                                cartItem={item}
                                onAgree={handleChildrenRemove}
                              />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </AccordionSummary>

                    <AccordionDetails>
                      <Table aria-label="simple table">
                        <TableBody>
                          <TableRow>
                            <TableCell>{item?.title}</TableCell>
                            <TableCell>
                              <span className="td-span">
                                {item?.quantity || 1}
                              </span>{" "}
                              <span className="td-span">x</span>{" "}
                              <span className="td-span">${item?.amount}</span>
                            </TableCell>
                            <TableCell className="td-right">
                              ${item?.amount * (item?.quantity || 1)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell colSpan={2}>Quantity</TableCell>
                            <TableCell className="td-right">
                              {item?.quantity}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              {item?.selectedDate
                                ? "Puja Date"
                                : "Donation Date"}{" "}
                            </TableCell>
                            <TableCell className="td-right" colSpan={2}>
                              {item?.selectedDate ? (
                                <span>
                                  {moment
                                    .utc(new Date(item?.selectedDate))
                                    .format("dddd, MMMM Do YYYY")}
                                </span>
                              ) : (
                                <span>
                                  {moment
                                    .utc(new Date())
                                    .format("dddd, MMMM Do YYYY")}
                                </span>
                              )}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              colSpan={3}
                              className="service-details-book"
                            >
                              {" "}
                              Book online - attend in person
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </AccordionDetails>
                  </Accordion>
                </>
              ))}
          </>

          <div className="d-flex align-items-center custom-control custom-checkbox mb-3">
            <input
              type="checkbox"
              checked={makeDonationChecked}
              onChange={() => setMakeDonationChecked(!makeDonationChecked)}
              className="custom-control-input custom-checkbox-input"
              id="price-2"
            />
            <label className="custom-control-label service-details-book-input">
              I would like to make a <Link to="/donation">donation</Link>
            </label>
          </div>
          {makeDonationChecked && (
            <div className="d-flex justify-content-between border-bottom mt-auto p-3 flex-column">
              <TextField
                value={donationAmount}
                onChange={(e) => handleInputChange(e)}
                label="Donation Amount"
                id="outlined-size-normal"
              />
              {error ? <Typography>{error}</Typography> : ""}
              <div className="d-flex justify-content-between align-items-center gap-3 my-2">
                <Select
                  labelId="demo-simple-select-label"
                  label="Select Donation Category"
                  onChange={(e) => {
                    setDonationItem(e.target.value);
                  }}
                  defaultValue={donationItem}
                >
                  {donationData
                    ?.sort((a, b) => a.title.localeCompare(b.title)) // Sort alphabetically by title
                    .map((item) => (
                      <MenuItem key={item._id} value={item}>
                        {item.title}
                      </MenuItem>
                    ))}
                </Select>
                <button
                  className="btn btn2 btn-dark rounded"
                  onClick={handleAddDonations}
                >
                  Add
                </button>
              </div>
            </div>
          )}

          <ul className="detail-list">
            <li className="service-details-total">
              Total Amount: <span>$ {totalAmount}</span>
            </li>
          </ul>
          <Button
            variant="warning text-white btn-slider slider-btn"
            onClick={handleProceedToPay}
          >
            Proceed to pay
          </Button>
        </div>
      </div>
    </div>
  );
}

export default PoojaBasket;
