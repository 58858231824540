import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./report.css";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  TextField,
  FormGroup,
  Grid,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Paper,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Button, Container, Table } from "react-bootstrap";
import Typography from "@mui/material/Typography";
import { apiEndPoints } from "../../ApiServices/config";
import { getApi, postApi } from "../../ApiServices/api";
import toast from "react-hot-toast";
import { AddCircle, Remove, RemoveCircle } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import html2pdf from "html2pdf.js";

function Report() {
  const devoteeData = JSON.parse(localStorage.getItem("devoteeData"));
  const [data, setData] = useState(null);
  const years = [2024, 2025, 2026, 2027, 2028, 2029, 2030];

  const capitalize = (str) => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const {
    watch,
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const handleDownload = () => {
    const element = document.getElementById("report");
    html2pdf()
      .set({
        margin: 0,
        filename: "DevoteeReport.pdf",
        html2canvas: { scale: 1 },
        jsPDF: { orientation: "portrait" },
      })
      .from(element)
      .save();
  };

  const onSubmit = async (data) => {
    if (data?.service || data?.donation) {
      data.user_id = devoteeData?.user_id;
      try {
        const response = await postApi(apiEndPoints.getReportDetail, data);
        if (response?.data?.status === 0) {
          toast.error(response?.data?.message);
        } else if (response?.data?.status === 1) {
          setData(response?.data?.data);
          toast.success(response?.data?.message);
        } else {
          toast.error("Something Went Wrong");
        }
      } catch (error) {
        toast.error(`Registration failed: , ${error}`);
      }
    } else {
      toast.error("Please Select at least one from Service or Donation");
    }
  };

  const sendMail = async () => {
    try {
      const data = {
        user_id: devoteeData?.user_id,
        service: watch("service"),
        donation: watch("donation"),
        year: watch("year"),
      };
      const response = await postApi(apiEndPoints?.sendReportMail, data);
      if (response?.data?.status === 0) {
        toast.error(response?.data?.message);
      } else if (response?.data?.status === 1) {
        setData(response?.data?.data);
        toast.success(response?.data?.message);
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      console.log("error : ", error);
      toast.error("Something Went Wrong");
    }
  };

  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">Report</h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active">Report</li>
        </ol>
      </div>
      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Devotee
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary"> Service & Donation Report</span>
          </h1>
        </div>
        <Box
          sx={{
            border: "1px solid #d0d7de",
            padding: "28px",
            borderRadius: "8px",
            backgroundColor: "#f9f9f9",
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <h4 className="registration-h4">Report Selection Criteria</h4>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <Controller
                  control={control}
                  rules={{ required: "Year is required" }}
                  name="year"
                  render={({ field, fieldState }) => {
                    return (
                      <FormControl
                        key={field.value}
                        fullWidth
                        error={fieldState?.error?.message}
                        className="dropdown-margin-top"
                      >
                        <InputLabel id="demo-simple-select-label">
                          Year
                        </InputLabel>
                        <Select
                          {...field}
                          labelId="demo-simple-select-label"
                          label="Year"
                          defaultValue={field.value}
                        >
                          <MenuItem value="">Select Year</MenuItem>
                          {years?.map((item) => (
                            <MenuItem key={item._id} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText error>
                          {fieldState?.error?.message}
                        </FormHelperText>
                      </FormControl>
                    );
                  }}
                />
              </div>
              <div className="col-lg-2 col-md-6">
                <FormGroup>
                  <FormControlLabel
                    {...register("service")}
                    control={
                      <Checkbox
                        {...register("service")}
                        value={watch("service")}
                      />
                    }
                    label="Service"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...register("donation")}
                        value={watch("donation")}
                      />
                    }
                    label="Donation"
                  />
                </FormGroup>
                {errors.service || errors.donation ? (
                  <FormHelperText error>
                    Please select at least one option.
                  </FormHelperText>
                ) : null}
              </div>
              <div className="col-lg-6 col-md-6 d-flex align-items-center flex-start">
                <Button
                  className="btn-small"
                  variant="text-white mx-1 py-2 px-3"
                  type="submit"
                >
                  Submit
                </Button>
              </div>
            </div>
          </form>
          <Button
            className="btn-small"
            disabled={data ? false : true}
            variant="text-white mx-1 py-2 px-3"
            onClick={() => {
              sendMail();
            }}
          >
            Send Mail
          </Button>
          <Button
            onClick={() => {
              handleDownload();
            }}
            className="btn-small"
            variant="text-white mx-1 py-2 px-3"
            disabled={data ? false : true}
          >
            Download
          </Button>
        </Box>
        {data && (
          <Box
            id="report"
            sx={{
              margin: "20px 0",
              border: "1px solid #d0d7de",
              padding: "28px",
              borderRadius: "8px",
              backgroundColor: "white",
            }}
          >
            <Container
              maxWidth="md"
              sx={{
                backgroundColor: "#fff",
                padding: "28px",
                border: "1px solid #d0d7de",
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center" // Align vertically in the center
                mb={5}
                sx={{
                  borderBottom: "1px solid lightgrey",
                  paddingBottom: "10px",
                }}
              >
                {/* Left Logo */}
                <Grid item>
                  <img src="./images/logo.png" alt="logo" width="150" />
                </Grid>

                {/* Center Text */}
                <Grid
                  item
                  xs={6} // Ensures the center text takes up space proportionally
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center" // Ensures text is centered properly
                >
                  <Typography
                    variant="h4"
                    sx={{ color: "#980b38", fontWeight: "bold" }}
                  >
                    Durga Temple
                  </Typography>
                  <Typography variant="h6" sx={{ color: "#ff4d16" }}>
                    (A tax-exempt non-profit organization)
                  </Typography>
                  <Typography
                    variant="body2"
                    align="center"
                    sx={{ color: "#243238", fontWeight: "bold" }}
                  >
                    8400 Durga Place, Fairfax Station, VA, 22039
                    <br />
                    Phone: 703-690-9355
                  </Typography>
                </Grid>

                {/* Right Logo */}
                <Grid item>
                  <img src="./images/logo2.png" alt="logo" width="100" />
                </Grid>
              </Grid>

              <Box sx={{ marginBottom: "28px" }}>
                <Typography variant="h6">
                  <strong>
                    {data?.user?.first_name + " " + data?.user?.last_name},
                  </strong>
                </Typography>
                <Typography variant="body2">
                  {data?.user?.address}
                  <br />
                  {data?.user?.city},{capitalize(data?.user?.name)}-
                  {data?.user?.zipcode}
                </Typography>
              </Box>

              <Typography variant="h6" sx={{ marginBottom: "18px" }}>
                <strong>Dear {data?.user?.first_name},</strong>
              </Typography>

              <Typography variant="body1" sx={{ marginBottom: "18px" }}>
                Durga Temple appreciates your contribution of the year{" "}
                {watch("year")}.
              </Typography>
              <TableContainer
                component={Paper}
                sx={{
                  margin: "16px 0",
                  width: "50% !important",
                  borderRadius: "0 !important",
                }}
              >
                <Table
                  sx={{
                    border: "1px solid #ccc !important",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "black !important", // White text color
                          fontWeight: "bold",
                          border: "1px solid #ccc !important",
                          padding: "8px",
                          textAlign: "center",
                          backgroundColor: "#ccc !important",
                        }}
                      >
                        Category
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "black !important",
                          fontWeight: "bold",
                          border: "1px solid #ccc !important",
                          padding: "8px",
                          textAlign: "center",
                          backgroundColor: "#ccc !important",
                        }}
                      >
                        Amount
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow sx={{ borderBottom: "1px solid #ccc" }}>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ccc !important",
                          padding: "8px",
                        }}
                      >
                        Services
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ccc !important",
                          padding: "8px",
                        }}
                      >
                        $ {data?.service_amount}
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ borderBottom: "1px solid #ccc" }}>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ccc !important",
                          padding: "8px",
                        }}
                      >
                        Donations
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ccc !important",
                          padding: "8px",
                        }}
                      >
                        $ {data?.donation_amount}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ccc !important",
                          padding: "8px",
                        }}
                      >
                        Total
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid #ccc !important",
                          padding: "8px",
                        }}
                      >
                        $ {data?.service_amount + data?.donation_amount}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <Typography variant="body1" sx={{ marginBottom: "18px" }}>
                <strong>
                  May Goddess Durga's blessings be with us always.
                </strong>
              </Typography>

              <Box sx={{ marginBottom: "38px" }}>
                <Typography variant="h6">
                  <strong>Regards,</strong>
                </Typography>
                <Typography variant="body1">
                  <strong>Durga Temple Management</strong>
                  <br />
                  8400 Durga Place,
                  <br />
                  Fairfax Station, VA, 22039
                </Typography>
              </Box>

              <Box
                sx={{
                  borderTop: "1px solid #c2c2c2",
                  paddingTop: "8px",
                  textAlign: "center",
                }}
              >
                <Typography variant="body1">
                  <strong>Note:</strong> This Report Includes{" "}
                  {watch("service") ? "Services" : ""},{" "}
                  {watch("donation") ? "Donations" : ""} paid through website
                  only.
                  <br />
                  Please contact your tax adviser for using this report to claim
                  tax exemption.
                </Typography>
              </Box>
            </Container>
          </Box>
        )}
      </div>
    </>
  );
}

export default Report;
