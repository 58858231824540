import React from "react";
import "./footer.css";
import { Button, TextField } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LanguageIcon from "@mui/icons-material/Language";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Link } from "react-router-dom";
import { useFooterFunctions } from "./CustomHook";

function Footer() {
  const {
    navigate,
    checkLoggedIn,
    commingSoon,
    onSubmit,
    register,
    handleSubmit,
    errors,
    reset,
  } = useFooterFunctions();

  return (
    <>
      <div className="container-fluid footer py-4">
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-md-6 col-lg-6 col-xl-3">
              <div className="footer-item d-flex flex-column">
                <h4 className="mb-4">Newsletter</h4>
                <p>
                  Please register your email address to be connected with temple
                  and receive regular newsletters and events.
                </p>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <TextField
                    className="input-field mb-2b"
                    id="first_name"
                    label="First Name"
                    variant="outlined"
                    {...register("first_name", {
                      required: "First Name is required",
                    })}
                    error={!!errors.first_name}
                    helperText={errors.first_name?.message}
                  />
                  <TextField
                    className="input-field mb-2b"
                    id="last_name"
                    label="Last Name"
                    variant="outlined"
                    {...register("last_name", {
                      required: "Last Name is required",
                    })}
                    error={!!errors.last_name}
                    helperText={errors.last_name?.message}
                  />
                  <TextField
                    className="input-field mb-2b"
                    id="email"
                    label="Email"
                    variant="outlined"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: /^\S+@\S+$/i,
                        message: "Invalid email",
                      },
                    })}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                  <TextField
                    className="input-field mb-2b"
                    id="contact_number"
                    label="Phone"
                    variant="outlined"
                    {...register("contact_number", {
                      required: "Phone number is required",
                    })}
                    error={!!errors.contact_number}
                    helperText={errors.contact_number?.message}
                  />
                  <Button
                    className="footer-btn py-3 px-5"
                    variant="contained"
                    type="submit"
                  >
                    Submit
                  </Button>
                </form>
              </div>{" "}
            </div>
            <div className="col-md-6 col-lg-6 col-xl-3">
              <div className="footer-item d-flex flex-column">
                <h4 className="mb-4">Information</h4>
                <Link to="/">
                  <KeyboardArrowRightIcon /> Home
                </Link>
                <Link to="/servicesattemple">
                  <KeyboardArrowRightIcon /> Services
                </Link>
                <Link to="/donation">
                  <KeyboardArrowRightIcon /> Donation
                </Link>
                <Link to="/events">
                  <KeyboardArrowRightIcon /> Events & Activities
                </Link>
                <Link to="/location">
                  <KeyboardArrowRightIcon /> Location Directions
                </Link>
                <Link to="/templehistory">
                  <KeyboardArrowRightIcon /> About Us
                </Link>
                <Link to="/contactus">
                  <KeyboardArrowRightIcon /> Contact Us
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-3">
              <div className="footer-item d-flex flex-column">
                <h4 className="mb-4">Others</h4>
                <Link to="/faq">
                  <KeyboardArrowRightIcon /> FAQ's
                </Link>
                <Link to="/gallery/photos">
                  <KeyboardArrowRightIcon /> Photo Gallery
                </Link>
                <Link to="/gallery/videos">
                  <KeyboardArrowRightIcon /> Video Gallery
                </Link>
                <Link to="/">
                  <KeyboardArrowRightIcon /> Sitemap
                </Link>
                <span className="cursor-pointer" onClick={checkLoggedIn}>
                  <KeyboardArrowRightIcon /> Volunteer Registration
                </span>
                <span className="cursor-pointer mt-1" onClick={commingSoon}>
                  <KeyboardArrowRightIcon /> Articles of Inc & Bylaws
                </span>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-3">
              <div className="footer-item d-flex flex-column">
                <h4 className="mb-4">Contact Info</h4>
                <Link to="/" className="icon-left">
                  <LocationOnIcon className="footer-icon" /> 8400 Durga Place,
                  Fairfax Station, VA, 22039
                </Link>
                <Link
                  href="mailto:contact@durgatemple.org"
                  className="icon-left"
                >
                  <MailOutlineIcon className="footer-icon" />
                  contact@durgatemple.org
                </Link>
                <Link to="/" className="icon-left">
                  <PhoneIcon className="footer-icon" /> 703-690-9355
                </Link>
                <div>
                  <h4 className="mt-4">Website issues</h4>
                  <Link
                    href="mailto:dt_website_support@durgatemple.org "
                    className="icon-left"
                  >
                    <LanguageIcon className="footer-icon" />
                    dt_website_support@durgatemple.org
                  </Link>
                </div>
                <div>
                  <h4 className="mt-3">Follow Us</h4>
                  <ul className="footer-icon-list">
                    <li>
                      <a
                        href="https://www.facebook.com/DurgaTemple"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <FacebookIcon />
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://www.youtube.com/@DurgaTempleLive/streams"
                      >
                        <YouTubeIcon />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/durgatemple/"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <InstagramIcon />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid copyright py-4">
        <div className="container">
          <div className="row g-4 align-items-center">
            <div className="col-md-6 text-center text-md-start mb-md-0">
              <span className="text-white">
                Copyright © 2024 <Link to="/">Durga Temple of Virginia</Link>,
                All right reserved.
              </span>
            </div>
            <div className="col-md-6 text-center text-md-end text-white">
              Designed & Developed by{" "}
              <a className="border-bottom" href="https://digiteratech.com/">
                Digitera Technologies
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Footer;
