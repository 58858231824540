import React, { useEffect, useState } from "react";
import "./priestbooking.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Button } from "react-bootstrap";
import { getApi } from "../../ApiServices/api";
import { apiEndPoints } from "../../ApiServices/config";
import { useSelector, useDispatch } from "react-redux";
import { updateCart } from "../../actions";
import { toast } from "react-hot-toast";
import PoojaBasket from "../../components/common/Basket/PoojaBasket";
import { Link } from "react-router-dom";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import { InfoOutlined } from "@mui/icons-material";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import Box from "@mui/material/Box";

function PriestBooking() {
  const [selectedDate, setSelectedDate] = useState({});
  const cartArr = useSelector((state) => state.cartArr);
  const isLoggedIn = localStorage.getItem("token");

  const dispatch = useDispatch();

  const [serviceData, setServiceData] = useState();

  const getWeeklyServicesData = async () => {
    try {
      const response = await getApi(apiEndPoints.getServicesOutsideTemple);
      setServiceData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getWeeklyServicesData();
  }, []);

  // order puja or add to basket
  const handleOrderPuja = (item) => {
    if (!selectedDate[item._id]) {
      toast.error("Please select a date.");
      return;
    }

    const existingCartItem = cartArr.find(
      (cartItem) => cartItem._id === item._id
    );

    if (existingCartItem) {
      const updatedItem = {
        ...existingCartItem,
        type: "Outside Service",
        type_id: 2,
        page: "outsidetemple",
        quantity: 1,
        selectedDate: selectedDate[item._id],
      };
      dispatch(updateCart(updatedItem));
    } else {
      const newItem = {
        ...item,
        type: "Outside Service",
        type_id: 2,
        page: "outsidetemple",
        quantity: 1,
        selectedDate: selectedDate[item._id],
      };
      dispatch(updateCart(newItem));
    }
  };

  const { control, formState } = useForm({});

  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <>
      <div className="container-fluid page-header py-4 breadcrumb-bg">
        <h1 className="text-center display-6 breadcrumb-h1">
          Outside Temple Services
        </h1>
        <ol className="breadcrumb justify-content-center mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/outsidetemple">Priest Booking</Link>
          </li>
        </ol>
      </div>
      <div className="container py-5">
        <div className="text-center mb-5">
          <h6 className="section-title text-center text-primary text-uppercase title-tl">
            Outside Temple
          </h6>
          <h1 className="mb-3">
            <span className="text-secundary">Priest Booking</span>
          </h1>
        </div>

        <div className="row">
          <p>
            <strong>Please Note:</strong> The schedule may be changed due to
            special events held in temple on that day.
          </p>
          <div className="row">
            <div className="col-lg-9 col-md-8">
              <div className="row">
                {serviceData?.map((i, index) => (
                  <div key={i._id} className="col-lg-4 col-md-6">
                    <div className="religious-services-box">
                      <div className="religious-service-item d-block rounded text-center h-100 p-3">
                        <img
                          className="img-fluid rounded mb-2"
                          src={i.image}
                          alt={i.title}
                        />
                        <h4 className="mb-1 religious-services-title">
                          {i.title}
                        </h4>
                        <div className="d-flex justify-content-between btm-mgn2">
                          <div>
                            <Box sx={{ minWidth: 225 }}>
                              <FormControl fullWidth className="drop-pad">
                                <InputLabel id="demo-simple-select-label">
                                  Priest Name
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={age}
                                  label="Age"
                                  onChange={handleChange}
                                >
                                  <MenuItem value={1}>
                                    Pandit Muralidhara Bhatta
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    Shastri Omkar Sharma
                                  </MenuItem>
                                  <MenuItem value={2}>
                                    Acharya Shanti Swaroop Vashisht
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Box>
                          </div>
                          <div>
                            <Tooltip title="https://durgatemple.org/priests">
                              <IconButton>
                                <InfoOutlined />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                        <Box sx={{ minWidth: 120 }}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Select Language
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={age}
                              label="Age"
                              onChange={handleChange}
                            >
                              <MenuItem value={1}>Hindi</MenuItem>
                              <MenuItem value={2}>Tamil</MenuItem>
                              <MenuItem value={3}>Telugu</MenuItem>
                              <MenuItem value={4}>Malayalam</MenuItem>
                              <MenuItem value={5}>English</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>

                        <div className="d-flex justify-content-between btm-mgn2">
                          <div className="religious-subtitle2 ">
                            <Box sx={{ minWidth: 120 }}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  label="Select a date"
                                  value={selectedDate[i._id]}
                                  onChange={(date) => {
                                    setSelectedDate((prevDates) => ({
                                      ...prevDates,
                                      [i._id]: date,
                                    }));
                                  }}
                                  shouldDisableDate={(date) => {
                                    const currentDate = moment
                                      .tz("America/New_York")
                                      .startOf("day");
                                    return date.isBefore(currentDate, "day");
                                  }}
                                />
                              </LocalizationProvider>
                            </Box>
                          </div>
                          <div>
                            <Box sx={{ minWidth: 90 }}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Time
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={age}
                                  label="Age"
                                  onChange={handleChange}
                                >
                                  <MenuItem value={1} className="avail-btn">
                                    09:00
                                  </MenuItem>
                                  <MenuItem
                                    disabled
                                    value={2}
                                    className="booked-btn"
                                  >
                                    10:00
                                  </MenuItem>
                                  <MenuItem
                                    disabled
                                    value={3}
                                    className="booked-btn"
                                  >
                                    11:00
                                  </MenuItem>
                                  <MenuItem value={4} className="avail-btn">
                                    12:00
                                  </MenuItem>
                                  <MenuItem
                                    disabled
                                    value={5}
                                    className="booked-btn"
                                  >
                                    13:00
                                  </MenuItem>
                                  <MenuItem
                                    disabled
                                    value={6}
                                    className="booked-btn"
                                  >
                                    14:00
                                  </MenuItem>
                                  <MenuItem value={7} className="avail-btn">
                                    15:00
                                  </MenuItem>
                                  <MenuItem value={8} className="avail-btn">
                                    16:00
                                  </MenuItem>
                                  <MenuItem value={9} className="avail-btn">
                                    17:00
                                  </MenuItem>
                                  <MenuItem
                                    disabled
                                    value={10}
                                    className="booked-btn"
                                  >
                                    18:00
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Box>
                          </div>
                        </div>
                        <div className="row d-flex justify-content-center align-items-center">
                          <div>
                            <Controller
                              control={control}
                              name={`items[${i._id}].location`}
                              render={({ field }) => (
                                <FormControl key={field.value} fullWidth>
                                  <InputLabel id={`location-label-${i._id}`}>
                                    Location
                                  </InputLabel>
                                  <Select
                                    {...field}
                                    labelId={`location-label-${i._id}`}
                                    label="Location"
                                  >
                                    <MenuItem value={"0-50miles"}>
                                      0 - 50 miles
                                    </MenuItem>
                                    <MenuItem value={"50-100miles"}>
                                      50 - 100 miles
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              )}
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <h2 className="religious-subtitle2">
                            <Button
                              variant="warning text-white btn-slider slider-btn religious-btn"
                              onClick={() => handleOrderPuja(i)}
                            >
                              Order Puja
                            </Button>
                          </h2>
                          <h2 className="religious-subtitle2">$ {i.amount}</h2>
                        </div>
                        <div
                          className="service-content-bdr truncate"
                          dangerouslySetInnerHTML={{
                            __html: i?.description,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="col-lg-3 col-md-4">
              <PoojaBasket />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PriestBooking;
