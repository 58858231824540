import ReactGA from "react-ga4";

export const initializeGA = (measurementId) => {
  ReactGA.initialize(measurementId);
};

export const logPageView = (pagePath) => {
  ReactGA.send({ hitType: "pageview", page: pagePath });
};

export const logEvent = (category, action, label) => {
  ReactGA.event({ category, action, label });
};
